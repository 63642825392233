import usuarioEntity from "@/model/usuario/usuario.entity";
import isFormPlanos from "@/model/carro/usecases/is-form-planos";
import isFormValija from "@/model/carro/usecases/is-form-valija";
import {crearSolicitudCliente} from "@/model/carro/carro.repository";

/**
 * Este método se encarga de verificar la integridad de los datos mostrados en el modal de pago para que no existan
 * modificaciones mal intencionadas por parte del cliente
 * @param data
 * @returns {Promise<unknown>}
 */
const validarFormularioPago = (data) => {

    console.log("data del formulario de pago", data);
    return new Promise((resolve, reject) => {

        usuarioEntity.isSessionActive().then((resp) => {
            if (resp) {

                /**
                 * Valida si las condiciones para enviar un formulario que contiene archivos de planos son correctas
                 */
                if (isFormPlanos() && data.retiro_planos == null) {
                    reject({
                        mensaje: "Verifique los elementos de su carro no hallan sido modificados e intentelo nuevamente"
                    })

                }

                /**ose
                 * Valida si las condiciones para crear una solicitud con valija electrónica son correctas
                 */
                if (isFormValija() && data.retiro_valija == null) {
                    reject({
                        mensaje: "Verifique los elementos de su carro no hallan sido modificados e intentelo nuevamente"
                    })

                }

                crearSolicitudCliente(data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject({mensaje: error})
                })

            } else {
                reject({
                    response: null,
                    mensaje: "Se ha cerrado su sesión, por favor, vuelva a iniciar sesión para continuar"
                })
            }
        })

    })
}

export default validarFormularioPago;
