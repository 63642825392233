import {
    getEstadoVisualizacionInformacionInicioApi,
    setEstadoVisualizacionInformacionInicioApi
} from "@/model/usuario/usuario.repository";

export const ULTIMO_ESTADO_MENSAJE = 2;

export const setEstadoVisualizacionInformacionInicio = (id) =>{
    setEstadoVisualizacionInformacionInicioApi(id);
}

const getEstadoVisualizacionInformacionInicio = () =>{
    return getEstadoVisualizacionInformacionInicioApi();
}

export default getEstadoVisualizacionInformacionInicio;