<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-card-text class="pb-2">
        <v-row>
          <v-col cols="12" class="">
            <h3 class="pb-3 text-center success--text d-flex justify-center font-weight-medium">
              Agregar documentos al carro de compras
            </h3>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row
            v-if="documentosAgregados.length > 0">

          <v-col cols="8">
            <p class="mb-0" style="text-align: center">
              Los documentos especificados han sido sido agregados a su carro personal.
            </p>
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-center">

            <v-icon color="success" x-large>mdi-check-circle-outline</v-icon>

          </v-col>

          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>

        </v-row>
        <v-row
            v-if="documentosRepetidos.length > 0 ">

          <v-col cols="8">
            <p class="mb-0" style="text-align: center">
              Los siguientes documentos ya se encontraban en su carro de compras y no es necesario agregarlos
              nuevamente.
            </p>
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-center">
            <v-icon v-if="ver" color="info" x-large>mdi-information-outline</v-icon>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Certificado
                  </th>
                  <th class="text-left">
                    Indice
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in documentosRepetidos"
                    :key="item.id_tipo_certificado"
                >
                  <td>{{ item.tipo_certificado }}</td>
                  <td>{{ item.indice }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row
            v-if="documentosEnLimite.length > 0 ">

          <v-col cols="8">
            <p class="mb-0" style="text-align: center">
              Los siguientes documentos no pudieron ser agregados debido a que exceden el límite de documentos en una de
              sus solicitudes del carro de
              compras
            </p>
          </v-col>
          <v-col cols="4" class="d-flex align-center justify-center">
            <v-icon v-if="ver" color="warning" x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Certificado
                  </th>
                  <th class="text-left">
                    Indice
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="item in documentosEnLimite"
                    :key="item.id_tipo_certificado"
                >
                  <td>{{ item.tipo_certificado }}</td>
                  <td>{{ item.indice }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols="12" sm="6" class="pa-0 d-flex flex-column">
            <v-btn
                class="ma-2"
                outlined
                color="success"
                @click="irATramites()"
            >
              Seguir Comprando
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0 d-flex flex-column">

            <v-btn
                class="ma-2"
                outlined
                color="info"
                @click="irAlCarro()"

            >
              Ir al carro
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalDocumentoAgregado",
  props: {
    verDialog: {default: false},
    time: {default: null},
    documentosAgregados: {
      type: Array,
      default() {
        return []
      }
    },
    documentosRepetidos: {
      type: Array,
      default() {
        return []
      }
    },
    documentosEnLimite: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    irAlCarro() {
      this.$router.push("/carro");
    },
    irATramites() {
      this.$router.push("/tramites")
    }
  }
}
</script>

<style scoped>

</style>