<template>
  <v-app>
    <IndexCompoundDrawer v-model="showDrawer"/>
    <IndexCompoundHeader @show-drawer="handleShowDrawer()"/>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
      <ModalLogin/>
      <DialogSesionIniciada/>
      <DialogSesionFinalizada/>
      <ModalSeleccionarCarro/>
    </v-main>
    <IndexCompoundFooter/>
    <v-btn fab dark style="margin-bottom: 5rem" @click="sendToWhatsapp"
           color="green" fixed right small bottom>
      <v-icon dark>mdi-whatsapp</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import IndexCompoundDrawer from "./IndexCompoundDrawer.vue";
import IndexCompoundHeader from "./IndexCompoundHeader.vue";
import IndexCompoundFooter from "./IndexCompoundFooter.vue";
import ModalLogin from "@/components/Login/ModalLogin";
import DialogSesionIniciada from "@/components/Dialogs/DialogSesionIniciada";
import DialogSesionFinalizada from "@/components/Dialogs/DialogSesionFinalizada";
import ModalSeleccionarCarro from "@/components/ModalSeleccionarCarro";
import usuario from "@/model/usuario/usuario.entity";
//import TestFooter from "./TestFooter"
// import carro from "@/model/carro/carro.entity";
export default {
  name: "WebpageCompound",
  components: {
    ModalSeleccionarCarro,
    DialogSesionFinalizada,
    DialogSesionIniciada, ModalLogin, IndexCompoundFooter, IndexCompoundHeader, IndexCompoundDrawer
  },
  data: function () {
    return {
      showDrawer: false,
      nombreUsuario: ""
    };
  },
  methods: {
    handleShowDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    sendToWhatsapp() {
      let nombre = usuario.getNombreUsuario();
      let saludo = "Hola, "

      if (nombre != null) {
        saludo += "mi nombre es " + nombre + ", "
      }

      saludo += "tengo una consulta "

      var win = window.open(`https://wa.me/56936343673?text=${saludo}`, '_blank');
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      }
    }
  },
  created() {

    // carro.getCarro().then(response=>{
    //   console.log("modal-pago",response);
    // })
  },


};
</script>

<style>

</style>
