<template>
  <div>
    <div v-if="listaTitulos.length == 0" class="pt-3">
      <v-alert class="text-center" color="info" dense text outlined>
        Para los datos citados no se ha encontrado un título con Escritura disponible
      </v-alert>
    </div>
    <div v-if="listaTitulos.length > 0" class="">
      <div class="pt-2 pb-6 pr-extraSmallChris">
        <v-text-field
            class="pr-extraSmallChris"
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtrar resultados"
            hide-details
        />
      </div>
      <v-data-table
          dense
          :headers="headers"
          :items="listaTitulosFiltrados"
          class="mb-0 pb-0 ocultar-footer"
          :footer-props="{
            showCurrentPage:false,
            disablePagination:false,
            showFirstLastPage: false,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            itemsPerPageText: '',
            pageText: ''
      }"
      >
        <template class="" v-slot:item="{item}">
          <tr>
            <td class="text-start px-0">
              <div class="py-1 px-0" style="font-size: 12px">
                <div class="font-weight-medium px-0">{{ item.nombre_completo }}</div>
                <div class="py-1 px-0 d-flex align-center" style="font-size: 12px">
                  <div>
                    <v-icon color="primary" left small>mdi-map-marker</v-icon>
                  </div>
                  <div>
                    {{ item.ubicacion }}
                    <span class="info--text pointer" v-if="getRutUsuario == null" @click="verMas()">Ver más </span>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div class="py-1 px-0" style="font-size: 12px">
                {{ item.numero_repertorio }}
              </div>
            </td>
            <td>
              <div class="py-1 px-0 text-center" style="font-size: 12px">
                {{ item.fecha_escritura | toDate }}
              </div>
            </td>
            <td>
              <div class="py-1 px-0 text-center" style="font-size: 12px">
                {{ item.notario }}
              </div>
            </td>
            <td>
              <div class="py-1 px-0 text-center" style="font-size: 12px">
                {{ item.comuna }}
              </div>
            </td>
            <td>
              <v-btn
                  v-if="puedeAgregarDatosEscritura(item) == 0"
                  color="info"
                  icon
                  @click="seleccionarItem(item)"
                  :disabled="cargandoDocumentos"
                  :loading="item.cargando"
              >
                <v-icon size="19">mdi-file-plus</v-icon>
              </v-btn>
              <v-btn
                  v-else
                  color="red"
                  icon
                  @click="mostrarModalProblemaEscritura(item)"
                  :disabled="cargandoDocumentos"
                  :loading="item.cargando"
              >
                <v-icon size="19">mdi-file-cancel</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";
import {NOTARIAS_COMUNAS} from "@/model/notarias-sii/notarias-sii.entity";

export default {
  name: "BusquedaTitularesPropiedadEscrituraTabla",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    listaTitulos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      page: 1,
      pages: 10,
      prevIcon: 'mdi-chevron-left',
      nextIcon: 'mdi-chevron-right',
      ver: true,
      search: '',
      cargandoDocumentos: false,
      itemSeleccionado: null, //usado para la recarga
      headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'start',
        //   filterable: false,
        //   value: 'name',
        // },
        {text: 'Titular', value: 'nombre_completo', align: 'center', sortable: false,},
        {text: 'Repertorio', value: 'indice', width: "30px", align: 'center', sortable: false,},
        {text: 'Fecha', value: 'anho_maximo_registro_notaria', align: 'center', sortable: false,},
        {text: 'Notario', width: "200px", value: 'anho_maximo_registro_notaria', align: 'center'},
        {text: 'Comuna', value: 'comuna', align: 'center', sortable: false},
        // {text: 'Ubicación', value: 'ubicacion', align: 'justify'},
        {filterable: false, sortable: false, align: 'center'},

      ],
    }
  },
  watch: {
    getRutUsuario() {
      console.log("ejecuta recarga")
      this.$emit("recargarBusqueda")
    }
  },
  computed: {
    getRutUsuario() {
      return usuarioEntity.getRutUsuario()
    },
    listaTitulosFiltrados() {
      let busqueda = this.search.toLowerCase()
      if (this.search == null || this.search.trim() == "") {
        return this.listaTitulos
      } else {
        return this.listaTitulos.filter(f => {
          return f.nombre_completo.toLowerCase().indexOf(busqueda) != -1 || f.indice.toLowerCase().indexOf(busqueda) != -1 || f.ubicacion.toLowerCase().indexOf(busqueda) != -1
        })
      }
    },

  },
  methods: {
    renderItem(item) {
      return item === this.currentPage
          ? `<span>${item}</span>` // Página actual sin enlace
          : `<a href="#" @click.prevent="currentPage = ${item}">${item}</a>`;
    },
    addButtonToFooter() {
      const footer = this.$refs.myTable.$refs.footer

      // Crea un nuevo botón y lo agrega al inicio del footer
      const button = document.createElement('button')
      button.textContent = 'Mi botón'
      button.addEventListener('click', () => {
        alert('¡Hiciste clic en el botón!')
      })
      footer.insertBefore(button, footer.firstChild)
    },
    verMas() {
      EventBus.$emit("modal-login", true);
    },

    /**
     * Selecciona el ítem de titulo+escritura para agregarlo como posible documento a comprar
     * @param item item que contiene la información del título+escritura
     */
    seleccionarItem(item) {
      this.$emit("seleccionarItem", item);
    },
    /*
    verDocumento(item) {
        usuarioEntity.isSessionActive().then(res => {

            if (res) {
                window.open(`/visor-documentos/${item.indice}/${item.documento}/d`, '_blank');
            } else {
                EventBus.$emit("modal-login", true);
            }
        })

    },*/
    /**
     * función que determina si se puede agregar o no la información dependiendo de:
     * la notaría corresponde a la comuna de la serena (id_comuna = 30) y
     * el año máximo en que se tienen registros de la notaría es menor o igual al año de la escritura
     * @param itemEscritura el ítem que viene desde el backend con la información de la escritura
     * @returns 0: puede agergar.
     * @returns -1: no se puede agregar porque el notario NO es de la comuna de La Serena.
     * @returns -2: no se puede agregar porque registros del año en notaría.
     * */
    puedeAgregarDatosEscritura(itemEscritura) {

      const date = new Date(itemEscritura.fecha_escritura);
      const anhoEscritura = parseInt(date.getFullYear());

      if (parseInt(itemEscritura.id_comuna_FK) !== NOTARIAS_COMUNAS.LA_SERENA) {
        return -1;
      }

      if (parseInt(itemEscritura.anho_maximo_registro_notaria) < anhoEscritura) {
        return -2;
      }

      return 0;
    },
    mostrarModalProblemaEscritura(item) {

      let data = {};
      data.item = item;

      //const date = new Date(item.fecha_escritura);
      //const anhoEscritura = parseInt(date.getFullYear());

      switch (this.puedeAgregarDatosEscritura(item)) {
        case -1:
          data.mensaje = `Sólo es posible solicitar Copias de Escrituras firmadas en notarías de la comuna de La Serena.`;
          break;
        case -2:
          data.mensaje = `De momento sólo es posible solicitar copias de escrituras firmadas hasta el año ${item.anho_maximo_registro_notaria} para el notario ${item.notario}.`;
          break;
      }

      this.$emit("mostrarModalProblemaEscritura", data);
    }
  }
}
</script>

<style scoped>
/deep/ .ocultar-footer > div:nth-child(2) > div:first-child {
  display: none;
}
.v-application--is-ltr .v-data-footer__select{
  display: none;
}
@media (max-width: 600px) {
  .pr-extraSmallChris {
    padding-right: 2px;
    padding-left: 2px;
  }
}
</style>
