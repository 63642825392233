import {obtenerCarroActual} from "@/model/carro/carro.repository";

const getDatosAdicionalesCertificado = (id_tipo_registro, id_area_agrupacion) => {
    if(!id_area_agrupacion){
        console.error("ERROR: NO SE HA DEFINIDO EL AREA AGRUPACION")
        return null;
    }
    let carro = obtenerCarroActual();
    let escritura;
    // switch (id_tipo_registro) {
    //     case ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS:
    //         escritura = carro.find(f => f.id_tipo_registro_FK === ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS);
    //         console.log("Escritura: ", escritura);
    //         if (escritura) {
    //             return escritura.datos_adicionales;
    //         } else {
    //             return null;
    //         }
    //     case ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD:

    escritura = carro.find(f => f.id_tipo_registro_FK === id_tipo_registro && f.id_area_agrupacion == id_area_agrupacion) ;
    if (escritura) {
        return escritura.datos_adicionales;
    } else {
        return null;
    }

    // default:
    //     return null;

// }
}

export default getDatosAdicionalesCertificado;
