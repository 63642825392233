var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.listaTitulos.length == 0)?_c('div',{staticClass:"pt-3"},[_c('v-alert',{staticClass:"text-center",attrs:{"color":"info","dense":"","text":"","outlined":""}},[_vm._v(" Para los datos citados no se ha encontrado un título con Escritura disponible ")])],1):_vm._e(),(_vm.listaTitulos.length > 0)?_c('div',{},[_c('div',{staticClass:"pt-2 pb-6 pr-extraSmallChris"},[_c('v-text-field',{staticClass:"pr-extraSmallChris",attrs:{"append-icon":"mdi-magnify","label":"Filtrar resultados","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"mb-0 pb-0 ocultar-footer",attrs:{"dense":"","headers":_vm.headers,"items":_vm.listaTitulosFiltrados,"footer-props":{
          showCurrentPage:false,
          disablePagination:false,
          showFirstLastPage: false,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          itemsPerPageText: '',
          pageText: ''
    }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-start px-0"},[_c('div',{staticClass:"py-1 px-0",staticStyle:{"font-size":"12px"}},[_c('div',{staticClass:"font-weight-medium px-0"},[_vm._v(_vm._s(item.nombre_completo))]),_c('div',{staticClass:"py-1 px-0 d-flex align-center",staticStyle:{"font-size":"12px"}},[_c('div',[_c('v-icon',{attrs:{"color":"primary","left":"","small":""}},[_vm._v("mdi-map-marker")])],1),_c('div',[_vm._v(" "+_vm._s(item.ubicacion)+" "),(_vm.getRutUsuario == null)?_c('span',{staticClass:"info--text pointer",on:{"click":function($event){return _vm.verMas()}}},[_vm._v("Ver más ")]):_vm._e()])])])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"py-1 px-0",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.numero_repertorio)+" ")])]),_c('td',[_c('div',{staticClass:"py-1 px-0 text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm._f("toDate")(item.fecha_escritura))+" ")])]),_c('td',[_c('div',{staticClass:"py-1 px-0 text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.notario)+" ")])]),_c('td',[_c('div',{staticClass:"py-1 px-0 text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.comuna)+" ")])]),_c('td',[(_vm.puedeAgregarDatosEscritura(item) == 0)?_c('v-btn',{attrs:{"color":"info","icon":"","disabled":_vm.cargandoDocumentos,"loading":item.cargando},on:{"click":function($event){return _vm.seleccionarItem(item)}}},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("mdi-file-plus")])],1):_c('v-btn',{attrs:{"color":"red","icon":"","disabled":_vm.cargandoDocumentos,"loading":item.cargando},on:{"click":function($event){return _vm.mostrarModalProblemaEscritura(item)}}},[_c('v-icon',{attrs:{"size":"19"}},[_vm._v("mdi-file-cancel")])],1)],1)])]}}],null,false,1177594585)})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }