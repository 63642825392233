import {obtenerCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";

const getAlzamientoCarro = () => {

    let carro = obtenerCarroActual();

    let documentosAlzamiento = carro.filter(f => {
        return f.id_tipo_registro_FK === ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION;
    })

    if (documentosAlzamiento.length > 0) {
        let doc = documentosAlzamiento[0]


        let formularioAlzamiento = {
            idDocumento: doc.alzamiento.id_documento_electronico != null ? doc.alzamiento.id_documento_electronico : null,
            id_tipo_formulario: ID_TIPO_FORMULARIO.ALZAMIENTO_Y_CANCELACION,
            notario: {nombre: doc.alzamiento.notaria != null ? doc.alzamiento.notaria : null},
            partes: doc.alzamiento.partes != null ? doc.alzamiento.partes : null,
            repertorio: doc.alzamiento.repertorio != null ? doc.alzamiento.repertorio : null,
        }

        return formularioAlzamiento;
    }

    return null;


}

export default getAlzamientoCarro
