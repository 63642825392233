<template>
  <div>
    <v-fade-transition>
      <v-card class="px-4">
        <v-card-title class="pb-0">
          Solicitud de documentos
        </v-card-title>
        <v-card-text>
          <p class="mb-3">Verifique los documentos que se agregarán a su carro</p>

          <p
              v-if="titulo == null"
              class="text-center"
          >
            No hay informacion asociada al índice ingresado
          </p>

          <div v-if="titulo != null" class="pt-1">

            <!-- REGISTRO DE PROPIEDAD -->
            <v-row>
              <v-col cols="12 my-0 pt-0 pb-1 px-1">
                <div class="text--h3 pl-2 py-1 info--text font-weight-medium" style="font-size: 18px">
                  Registro de Propiedad
                  <v-divider/>
                </div>
              </v-col>
            </v-row>
            <!-- copia con vigencia propiedad buscada-->
            <v-row class="py-1">
              <v-col cols="9" class="d-flex align-center pb-sm-0 pt-0 pb-0 px-1">
                <descripcion-item-scbr-estudio-titulo
                    :tipo_certificado="copias.vigencia.tipo_certificado"
                    :indice="copias.vigencia.indice"
                />
              </v-col>

              <v-col cols="3" class="d-flex justify-end align-start pr-4 py-0">
                {{ copias.vigencia.precio| clp }}
              </v-col>

              <!--<v-col cols="12" class="pb-0 pt-2 px-1">
                <div class="pr-2 pl-8">
                  <small style="line-height: 17px" class="d-flex text-justify font-weight-regular">
                    <v-icon color="info" class="mb-4 mr-1" x-small>mdi-map-marker</v-icon>
                    {{ copias.vigencia.ubicacion }}</small>
                </div>
              </v-col>-->
            </v-row>
            <!-- GP -->
            <v-row class="pt-2 pb-2" v-if="copias.gp.numero_copias > 0">
              <v-col cols="9" class="d-flex align-center pb-sm-0 pt-0 px-1">
                <descripcion-item-scbr-estudio-titulo
                    :tipo_certificado="copias.gp.tipo_certificado"
                />
                <!--esto iba arriba xd :info="`Si no hay vigencia para el título ${dataFormulario.foja}-${dataFormulario.numero}-${dataFormulario.anho} se entregará el certificado del título vigente`" -->

              </v-col>

              <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0">
                {{ copias.gp.precio * copias.gp.numero_copias| clp }}
              </v-col>
            </v-row>
            <!-- copia autorizada (sin vigencia) tit. anteriores-->
            <v-row class="py-2" v-if="copias.autorizadas.numero_copias > 0">
              <v-col cols="9" class="d-flex align-center  pb-sm-0  pt-0 px-1">
                <descripcion-item-scbr-estudio-titulo
                    tipo_certificado="Copia Autorizada para títulos anteriores"
                    :cantidad="copias.autorizadas.numero_copias"
                />
              </v-col>

              <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0 px-1">
                {{ copias.autorizadas.precio * copias.autorizadas.numero_copias| clp }}
              </v-col>

            </v-row>
            <!-- REGISTROS DE HIP Y PRH -->
            <v-row v-if="copiasHipPro.numero_copias > 0">
              <v-col cols="12 my-0 pb-0 pt-3 px-1">
                <div class="text--h3 pl-2 py-1 info--text font-weight-medium" style="font-size: 18px">
                  Registros de Hipotecas y Prohibiciones
                  <v-divider/>
                </div>
              </v-col>
            </v-row>

            <!-- copia autorizada hipoteca / prohibición (expand)-->

            <v-row
                v-if="copiasHipPro.numero_copias > 0"
                class="pt-2 pb-0"
                @click="expandHipPro = !expandHipPro"
            >
              <v-col cols="9" class="d-flex align-center px-1 pb-0 pt-0 ">
                <descripcion-item-scbr-estudio-titulo
                    class="pb-0"
                    tipo_certificado="Copia Autorizada"
                    :cantidad="copiasHipPro.numero_copias"
                />

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="ml-2"
                        icon
                        color="info"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon>
                        mdi-information-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Presione para visualizar un desglose de los ítems</span>
                </v-tooltip>
              </v-col>

              <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0">
                {{ copiasHipPro.precioTotal | clp }}
              </v-col>
            </v-row>

            <v-expand-transition
                v-if="copiasHipPro.numero_copias > 0"
            >
              <div v-show="expandHipPro">
                <v-row
                    v-for="(copia,index) in listaCopiasHipProAgrupadas"
                    :key="index"
                >
                  <v-col cols="9" class="d-flex align-center px-1 pb-0 pt-0">
                    <div class="pl-4">
                      <small class="d-flex info--text pl-4">
                        {{ copia.tipo }} x {{ copia.cantidad }}
                      </small>
                    </div>
                  </v-col>
                  <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0">
                    <small class="d-flex info--text">
                      {{ copia.precio_total | clp }}
                    </small>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <!-- copia autorizada hipoteca -->
            <!--
            <v-row class="py-3">

              <v-col cols="9" class="d-flex align-center  pb-sm-0  pt-0 ">
                <descripcion-item-scbr-estudio-titulo
                    :tipo_certificado="copias.hipotecas.tipo_certificado"
                    :indice="`Cantidad de inscripciones: x ${copias.hipotecas.numero_copias}`"
                />
              </v-col>

              <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0">
                {{ copias.hipotecas.precio * copias.hipotecas.numero_copias | clp }}
              </v-col>

            </v-row>
            -->
            <!-- copia autorizada prohibición -->
            <!--
            <v-row class="py-3">

              <v-col cols="9" class="d-flex align-center  pb-sm-0  pt-0 ">
                <descripcion-item-scbr-estudio-titulo
                    :tipo_certificado="copias.prohibiciones.tipo_certificado"
                    :indice="`Cantidad de inscripciones: x ${copias.prohibiciones.numero_copias}`"
                />
              </v-col>

              <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0">
                {{ copias.prohibiciones.precio * copias.prohibiciones.numero_copias | clp }}
              </v-col>

            </v-row>
            -->
            <!-- ARCHIVERO JUDICIAL -->
            <v-row v-if="copias.estudio_titulo_citado.numero_copias > 0 || copias.estudio_titulo.numero_copias > 0">
              <v-col cols="12 my-0 pb-0 pt-3 px-1">
                <div class="text--h3 pl-2 py-1 info--text font-weight-medium" style="font-size: 18px">
                  Archivero Judicial
                  <v-divider/>
                </div>
              </v-col>
            </v-row>
            <!-- copia de escritura tit. buscado-->
            <v-row class="py-2" v-if="copias.estudio_titulo_citado.numero_copias > 0">
              <v-col cols="9" class="d-flex align-center px-1 pb-sm-0 px-1 pt-0 ">
                <descripcion-item-scbr-estudio-titulo
                    :tipo_certificado="'Copia de escritura'"
                    :info="'Corresponde a la escritura pública que dio origen a la inscripción '+ dataFormulario.foja +'-'+ dataFormulario.numero + '-' +dataFormulario.anho "
                    :cantidad="copias.estudio_titulo_citado.numero_copias"
                />
              </v-col>

              <v-col cols="3" class="d-flex  justify-end pr-4 align-start py-0">
                {{ copias.escrituras_publicas.precio * copias.estudio_titulo_citado.numero_copias| clp }}
              </v-col>
            </v-row>
            <!-- copia de escritura tit. anteriores-->
            <v-row class="py-2" v-if="copias.estudio_titulo.numero_copias > 0">

              <v-col cols="9" class="d-flex align-center px-1 pb-sm-0  pt-0 ">
                <descripcion-item-scbr-estudio-titulo
                    :tipo_certificado="'Copia de escritura'"
                    :info="'Escrituras Públicas que dieron origen a los títulos anteriores de la inscripción '+ dataFormulario.foja +'-'+ dataFormulario.numero + '-' +dataFormulario.anho"
                    :cantidad="copias.estudio_titulo.numero_copias"
                />
              </v-col>

              <v-col cols="3" class="d-flex justify-end pr-4 align-start py-0">
                {{ copias.escrituras_publicas.precio * copias.estudio_titulo.numero_copias| clp }}
              </v-col>

            </v-row>
            <!-- total -->
            <!-- ARCHIVERO JUDICIAL -->
            <v-row>
              <v-col cols="12 my-0 pb-0 pt-3 px-1 pr-4">
                <v-divider/>
                <div class="text--h3 text-right pt-4 font-weight-medium" style="font-size: 18px">
                  Total: {{ totalDeCompra | clp }}
                </div>
              </v-col>
            </v-row>
            <!-- INFO -->
            <!-- info archivero judicial -->
            <v-row>

              <v-col cols="12">
                <v-alert class="py-0" text outlined rounded color="info">
                  <div class="d-flex my-4">
                    <div class="mt-2">
                      <v-checkbox
                          color="blue"
                          hide-details
                          class="ml-0 pl-0 prueba"
                          v-model="deshabilitarBtnAgregarCarro"
                      />
                    </div>
                    <div class="">Entiendo que el valor calculado es aproximado y corresponde a una <b>GARANTÍA</b>.
                      En caso de
                      existir un saldo por concepto de carillaje será
                      informado vía correo.
                    </div>
                  </div>
                </v-alert>
              </v-col>

              <v-col cols="12" class="pt-3 text-center">
                <v-btn left color="success" :disabled="!deshabilitarBtnAgregarCarro" @click="agregarAlCarro()" outlined>
                  <v-icon>mdi-cart-plus</v-icon>
                  Agregar al carro
                </v-btn>
              </v-col>

            </v-row>

          </div>
        </v-card-text>
      </v-card>
    </v-fade-transition>

    <modal-documento-agregado
        :verDialog="modalAgregar.verModalDocumentoAgregado"
        :documentosAgregados="modalAgregar.lista_agregados"
        :documentosRepetidos="modalAgregar.documentos_repetidos"
        :documentosEnLimite="modalAgregar.limite_carro"
        @ocultarDialog="modalAgregar.verModalDocumentoAgregado = false"
    />
    <modal-estudio-titulo-restringido
        :ver-dialog="verModalCarroRestringido"
        @ocultarDialog="verModalCarroRestringido = false"
    />
  </div>
</template>

<script>
// import {DIVISA} from "@/model/inscripciones/inscripciones.entity";
import carroEntity from "@/model/carro/carro.entity";
import ModalDocumentoAgregado from "@/components/ModalDocumentoAgregado";
import {ID_TIPO_TRAMITE} from "@/model/tramites/tramites.entity";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";
import ModalEstudioTituloRestringido from "@/views/index/estudio-titulo/modalEstudioTituloRestringido";
import DescripcionItemScbrEstudioTitulo from "@/components/DescripcionItemScbrEstudioTitulo";

/**
 * En este componente se encuentra toda la vista y despliegue de los certificados que se pueden agregar después de
 * realizar la validación con scbr
 */

export default {
  name: "ListaCopiasCertificadosTitulo",
  components: {
    DescripcionItemScbrEstudioTitulo,
    ModalEstudioTituloRestringido,
    ModalDocumentoAgregado
  },

  props: {
    otrasCopias: {
      type: Object,
      default: () => {
      }
    },
    certificados_utilizados: {
      type: Object,
      default: () => {

      }
    },
    certificados: {
      type: Object,
      default: () => {
      }
    },
    titulo: {
      type: Object,
      default: () => {
      }
    },
    titulos_anteriores: {
      type: Array,
      default: () => []
    },
    dataFormulario: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      deshabilitarBtnAgregarCarro: false,
      lista_certificados: [],
      lista_hipotecas_prohibiciones: [],
      certificado: null,
      modalAgregar: {
        verModalDocumentoAgregado: false
      },
      copias: {
        vigencia: {},
        autorizadas: {},
        hipotecas: {},
        prohibiciones: {},
        gp: {},
        escrituras_publicas: {},
        estudio_titulo: {},
        estudio_titulo_citado: {}
      },
      precio_estudio: 0,
      checkAgregarEscritura: true,
      verModalCarroRestringido: false,
      //
      expandHipPro: false,
    }
  },

  /**
   * Al momento de montarse el componente se generan los objetos correspondientes a los items de carro de compra que
   * se agregarán según los resultados de las validaciones anteriores
   */
  mounted() {
    this.lista_hipotecas_prohibiciones = [];
    if (this.titulo != null) {


      let copias_vigencia = {
        ...this.certificados_utilizados.copia_con_vigencia,
        indice: this.titulo.indice,
        numero_copias: 1,
        ubicacion: this.titulo.ubicacion,
        titulo_referencial: this.titulo.indice,
        id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
      }


      let copias_autorizadas = {
        ...this.certificados_utilizados.copia_autorizada,
        numero_copias: this.otrasCopias.copias_autorizadas,
        id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
      }

      let copias_hipotecas = {
        ...this.certificados_utilizados.copia_hipoteca,
        lista_copias: this.otrasCopias.copias_hipotecas,
        id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO,
      }

      let copias_prohibiciones = {
        ...this.certificados_utilizados.copia_prohibicion,
        lista_copias: this.otrasCopias.copias_prohibiciones,
        id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO,
      }

      let certificado_gp = {
        ...this.certificados_utilizados.gp,
        numero_copias: 1,
        id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
      }

      let escrituras_publicas = {
        ...this.certificados_utilizados.escrituras_publicas,
        numero_copias: 1,
        id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
      }

      let estudio_titulo = {}

      let estudio_titulo_citado = {}
      console.log("some", this.otrasCopias.copias_escrituras.some(indice => indice == this.titulo.indice))
      if (!this.otrasCopias.copias_escrituras.some(indice => indice == this.titulo.indice)) {
        estudio_titulo = {
          ...this.certificados_utilizados.escrituras_publicas,
          numero_copias: this.otrasCopias.copias_escrituras.length,
          id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
        }
        estudio_titulo_citado = {
          ...this.certificados_utilizados.escrituras_publicas,
          numero_copias: 0,
          id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
        }
      } else {
        estudio_titulo_citado = {
          ...this.certificados_utilizados.escrituras_publicas,
          numero_copias: 1,
          id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
        }
        estudio_titulo = {
          ...this.certificados_utilizados.escrituras_publicas,
          numero_copias: this.otrasCopias.copias_escrituras.length - 1,
          id_tipo_tramite: ID_TIPO_TRAMITE.ESTUDIO_TITULO
        }
      }


      this.copias = {
        vigencia: copias_vigencia,
        autorizadas: copias_autorizadas,
        hipotecas: copias_hipotecas,
        prohibiciones: copias_prohibiciones,
        gp: certificado_gp,
        escrituras_publicas: escrituras_publicas,
        estudio_titulo: estudio_titulo,
        estudio_titulo_citado: estudio_titulo_citado
      }


      /**
       * luego de generar todos los objetos, se calcula el precio total, al final del proceso, toda la lista de items
       * se resumen a 1 item con el precio de la suma de todos los certificados obtenidos.
       */

      this.precio_estudio = this.copias.gp.precio +
          this.copias.vigencia.precio * this.copias.vigencia.numero_copias +
          this.copiasHipPro.precioTotal +
          this.copias.autorizadas.precio * this.copias.autorizadas.numero_copias;

    }
  },
  computed: {
    copiasHipPro() {
      let precioTotal = 0;
      let numeroCopias = 0;

      if (this.copias.hipotecas.lista_copias == null) {
        return {
          precioTotal: precioTotal,
          numero_copias: numeroCopias,
        }
      }

      precioTotal += this.copias.hipotecas.lista_copias.reduce((total, value) => {
        return total + value.precio_total;
      }, 0)

      precioTotal += this.copias.prohibiciones.lista_copias.reduce((total, value) => {
        return total + value.precio_total;
      }, 0)

      numeroCopias += this.copias.hipotecas.lista_copias.length;
      numeroCopias += this.copias.prohibiciones.lista_copias.length;

      return {
        precioTotal: precioTotal,
        numero_copias: numeroCopias,
      }
    },
    listaCopiasHipProAgrupadas() {

      console.log("this.copias.hipotecas.lista_copias: ", this.copias.hipotecas.lista_copias);

      /*
      * {
      *   tipo: "tipo_prohibicion" / "tipo_hipoteca",
      *   cantidad : calculado
      *   precio_total: "precio_total"
      * }
      * */
      let lista = [];

      let listaHipotecas = this.copias.hipotecas.lista_copias.map((item) => {
        let i = {
          tipo: item.tipo_hipoteca,
          cantidad: 1,
          precio_total: item.precio_total,
        }
        return i;
      });

      let listaProhibiciones = this.copias.prohibiciones.lista_copias.map((item) => {
        let i = {
          tipo: item.tipo_prohibicion,
          cantidad: 1,
          precio_total: item.precio_total,
        }
        return i;
      });

      lista = [...listaHipotecas, ...listaProhibiciones];

      //se agrupa (gracias chatGPT)
      const listaAgrupada = Object.values(
          lista.reduce((acc, cur) => {
            if (acc[cur.tipo]) {
              acc[cur.tipo].precio_total += cur.precio_total;
              acc[cur.tipo].cantidad += cur.cantidad;
            } else {
              acc[cur.tipo] = {tipo: cur.tipo, precio_total: cur.precio_total, cantidad: cur.cantidad};
            }
            return acc;
          }, {})
      );

      return listaAgrupada;

    },
    totalDeCompra() {
      let total = 0;
      total += this.copias.vigencia.precio;
      total += this.copias.gp.precio * this.copias.gp.numero_copias;
      total += this.copias.autorizadas.precio * this.copias.autorizadas.numero_copias;
      total += this.copiasHipPro.precioTotal;
      total += this.copias.escrituras_publicas.precio * this.copias.estudio_titulo_citado.numero_copias;
      total += this.copias.escrituras_publicas.precio * this.copias.estudio_titulo.numero_copias;
      return total;
    }
  },
  methods: {
    showTotal(item, precio) {
      if (item.numero_copias == 0) {
        return precio;
      } else {
        return item.numero_copias * precio
      }

    },
    agregarAlCarro() {

      let lista = [{
        ...this.certificados_utilizados.estudio_titulo,
        precio: this.precio_estudio,
        precio_pagado: this.precio_estudio,
        precio_cliente: this.precio_estudio,
        numero_copias: 1
      }]


      /**
       * El cliente puede indicar si desea o no agregar las copias de escritura, en caso de que el checkbox que habilita
       * esto esté en true, agregará items adicionales al carro.
       *
       * Los documentos que se deben agregar se cargaron al monento de validar el índice, pero es en este punto en el que
       * se agregan a la lista para enviarlos al carro
       */
      if (this.checkAgregarEscritura) {

        let indices_copia_escritura = []

        this.$set(this.dataFormulario, "id_tipo_formulario", ID_TIPO_FORMULARIO.FNA)

        this.otrasCopias.copias_escrituras.map(t => {
          indices_copia_escritura.push({
            ...this.certificados_utilizados.escrituras_publicas,
            precio_cliente: this.certificados_utilizados.escrituras_publicas.precio,
            precio_pagado: this.certificados_utilizados.escrituras_publicas.precio,
            numero_copias: 1,
            indice: t
          })
        })

        lista = [...lista, ...indices_copia_escritura]
        this.$set(this.dataFormulario, "datos_adicionales", [{
          nombre: "titulo",
          valor: this.dataFormulario.foja + "-" + this.dataFormulario.numero + "-" + this.dataFormulario.anho,
          id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
          id_area_agrupacion: ID_AREA_AGRUPACION.ESCRITURAS_PUBLICAS
        }])
        console.log("check agregar escritura true????", this.dataFormulario)
      }


      console.log("se manda: ", lista)
      carroEntity.agregarAlCarro(lista, this.dataFormulario, 0).then(response => {
        this.modalAgregar.verModalDocumentoAgregado = true;
        this.modalAgregar.documentos_repetidos = response.documentos_repetidos;
        this.modalAgregar.limite_carro = response.limite_carro
        this.modalAgregar.lista_agregados = response.lista_agregados;

      })
    }
  }

}
</script>

<style scoped>
.prueba::v-deep  .v-input--selection-controls__input{
  margin-right: 7px;
  padding-right: 0px;
}
.prueba::v-deep .mdi-checkbox-blank-outline::before{
  color:  rgba(50,125,255,1);
}

.v-input--selection-controls{
  margin-top: 0px;
  margin-bottom: 10px;
  padding-top: 0px;
}
</style>
