import {obtenerNotariasSii} from "@/model/notarias-sii/usecases/get-notarias-sii";

export const NOTARIAS_COMUNAS = {
    LA_SERENA: 30
}

const notariasSiiEntity = {
    obtenerNotariasSii
}

export default notariasSiiEntity