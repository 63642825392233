import {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";

export const getCertificadoPuedeBuscarPorTitulo = (id_tipo_registro, id_tipo_certificado) => {

    let certificados_con_busca_titulo = [
        {
            id_tipo_registro: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
            id_tipos_certificado: [
                ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA,
                ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE,
                ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES,
                ID_TIPO_CERTIFICADO.CERT_BIEN_FAMILIAR

            ]
        }
    ]

    let registro = certificados_con_busca_titulo.find(f => {
        return f.id_tipo_registro == id_tipo_registro
    })
    if (registro != null) {
        let certificado = registro.id_tipos_certificado.find(c => {
            return c == id_tipo_certificado
        })
        return certificado != null
    } else {
        return false;
    }

}
