import axios from "axios";

export const obtenerNotariasSiiFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            "/notarias-sii?action=obtener-notarias-sii"
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}
