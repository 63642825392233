import axios from "axios";
import qs from "qs";

export const getHipotecasYGravamenesTituloApi = (numero, anho) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-hipotecas-y-gravamenes-titulo",
            qs.stringify({

                numero,
                anho

            })
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getEstudioTituloApi = (numero, anho) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-estudio-titulo",
            qs.stringify({
                numero,
                anho
            })
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getConsultaTitularesApi = (nombre, anho) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-consulta-titulares",
            qs.stringify({nombre, anho})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getConsultaSociedadesApi = (nombre) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-consulta-sociedades",
            qs.stringify({nombre})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getNaturalezaComercioApi = (indice) =>{
    return new Promise((resolve, reject) =>{

        axios.post(
            "/titulos?action=get-naturaleza-comercio",
            qs.stringify({indice})
        ).then(function (response){
            resolve(response);
        }).catch(function (error){
            reject(error);
        });
    })
}

export const getConsultaTitularesByIndiceApi = (indice) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-consulta-titulares-by-indice",
            qs.stringify({indice})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getConsultaTitularesEscriturasApi = (nombre, anho) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-consulta-titulares-escrituras",
            qs.stringify({nombre, anho})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getEscrituraTituloFNA = (foja, numero, anho) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-titulares-escrituras-fna",
            qs.stringify({foja, numero, anho})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getDocumentosTentativosByTituloApi = (indice) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-documentos-tentativos-by-titulo",
            qs.stringify({indice})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getIndiceTituloApi = (indice) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-indice-titulo",
            qs.stringify({indice})
        ).then(function (response) {
            resolve(response.data);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getHipotecasYGravamenesTramitesTituloApi = (indice, id_tipo_certificado) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-hipoteca-prohibicion-titulo",
            qs.stringify({indice, id_tipo_certificado})
        ).then(function (response) {
            resolve(response.data.obj);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getContratantesApi = (numero, anho) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-contratantes",
            qs.stringify({numero, anho})
        ).then(function (response) {
            resolve(response.data.obj);
        }).catch(function (error) {
            reject(error);
        });
    })
}

export const getHipotecaProhibicionTituloApi = (objeto_titulo_has_prohibiciones) =>{
    return new Promise((resolve, reject) => {
        axios.post(
            "/titulos?action=get-glosa-titulo-has-prohibiciones",
            qs.stringify({objeto_titulo_has_prohibiciones:JSON.stringify(objeto_titulo_has_prohibiciones)})
        ).then(function (response) {
            resolve(response);
        }).catch(function (error) {
            reject(error);
        });
    })
}
