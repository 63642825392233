import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/lib/locale/es'

Vue.use(Vuetify);

export default new Vuetify({

    lang: {
        locales: {es},
        current: 'es',
    },
    theme: {
        themes: {
            light: {
                primary: '#19AEC5',
                secondary: '#FFAA00',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
});
