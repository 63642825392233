import getHipotecasYGravamenesTitulo from "@/model/titulo/usecases/get-hipotecas-y-gravamenes-titulo";
import getEstudioTitulo from "@/model/titulo/usecases/get-estudio-titulo";
import getConsultaTitulares from "@/model/titulo/usecases/get-consulta-titulares";
import getDocumentosTentativosByTitulo from "@/model/titulo/usecases/get-documentos-tentativos-by-titulo";
import getIndiceTitulo from "@/model/titulo/usecases/get-indice-titulo";
import getHipotecasYGravamenesTramitesTitulo from "@/model/titulo/usecases/get-hipotecas-y-gravamenes-tramites-titulo";
import getConsultaTitularesByIndice from "@/model/titulo/usecases/get-consulta-titulares-by-indice";
import {getContratantes} from "@/model/titulo/usecases/get-contratantes";
import getConsultaTitularesEscrituras from "@/model/titulo/usecases/get-consulta-titulares-escrituras";
import getConsultaSociedades from "@/model/titulo/usecases/get-consulta-sociedades";
import getNaturalezaComercio from "@/model/titulo/usecases/get-naturaleza-comercio";
import getHipotecaProhibicionTitulo from "@/model/titulo/usecases/get-hipoteca-prohibicion-titulo";
import {getEscrituraTituloFNA} from "@/model/titulo/titulo.repository";


const tituloEntity = {
    getEscrituraTituloFNA,
    getHipotecasYGravamenesTramitesTitulo,
    getHipotecasYGravamenesTitulo,
    getEstudioTitulo,
    getConsultaTitulares,
    getConsultaTitularesEscrituras,
    getConsultaTitularesByIndice,
    getDocumentosTentativosByTitulo,
    getIndiceTitulo,
    getConsultaSociedades,
    getNaturalezaComercio,
    getContratantes,
    getHipotecaProhibicionTitulo
}

export default tituloEntity;
