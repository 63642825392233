import {setPagoTransbankApi} from "@/model/carro/carro.repository";

const setPagoTransbank = (id_carro, token_ws) =>{
    return new Promise((resolve, reject) => {
        setPagoTransbankApi(id_carro, token_ws).then(resp=>{
            if(resp.status){
                resolve(resp.obj)
            }else{
                reject(resp)
            }

        }).catch(error=>{
            reject(error)
        })
    })
}

export default setPagoTransbank;