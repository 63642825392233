export const cleanDatoAdicional = (value) => {
    switch (value) {
        case "notario_extracto":
            return "Notario Extracto"
        case "id_documento_electronico_extracto":
            return "Código de verificación Extracto"
        case "formulario_2890":
            return "Formulario 2890";
        case "notario":
            return "Notario Escritura"
        case "id_documento_electronico":
            return "Código de verificación Escritura";
        case "Observaciones":
            return "Observación";
        case "Nombre de la sociedad":
            return "Nombre de la Sociedad";
        default:
            return value;

    }
}