<template>
  <v-form @submit.prevent="send()">
    <v-row v-if="buscandoNombre">
      <v-col class="pb-0 pt-0">
        <panel-buscar-por-titular
            :item-nav="itemNav"
            :registro="registro"
            :id_tipo_registro="id_tipo_registro"
            :ver_ayuda="false"
            @seleccionarItem="seleccionarItem($event)"
            :titular-escritura="false"
        />
      </v-col>
      <v-col cols="12" class="py-md-0 pt-0">
          <span @click="volver" style="font-size: 12px; cursor: pointer" class="info--text mb-0">
            <span>Volver a la pestaña anterior</span>
            <v-icon small pl-2 color="info">mdi-undo-variant</v-icon>
          </span>
            </v-col>
        </v-row>
        <v-row class="" v-if="!buscandoNombre">

            <v-col cols="12" sm="4" class="pb-0">
                <v-text-field
                        label="Foja"
                        placeholder="Ej: 1"
                        outlined
                        id="inputFoja"
                        dense
                        :autofocus="!$vuetify.breakpoint.mobile"
                        hide-details="auto"
                        v-model="foja"
                        :error-messages="errorFoja"
                        @input="delayTouch($v.foja)"
                        :disabled=" !this.camposHabilitados"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
                <v-text-field
                        label="Número"
                        placeholder="Ej: 1"
                        outlined
                        dense
                        hide-details="auto"
                        v-model="numero"
                        :error-messages="errorNumero"
                        @input="delayTouch($v.numero)"
                        :disabled=" !this.camposHabilitados"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0">
                <v-text-field
                        label="Año"
                        placeholder="Ej: 2022"
                        outlined
                        dense
                        hide-details="auto"
                        v-model="anho"
                        :error-messages="errors.errorAnho($v.anho)"
                        @input="delayTouch($v.anho)"
                        :disabled="!this.camposHabilitados"
                ></v-text-field>
            </v-col>

            <v-col
                    class="pt-1 pb-0"
                    cols="12"
                    v-if="busquedaNombre"
            >
                <small>¿No sabe su foja, número y año? Presione
                    <span v-if="this.camposHabilitados"
                          @click="cambiarFormaBusqueda"
                          style="cursor: pointer"
                          class="blue--text">
                      aquí
                    </span>
                    <span
                            v-if="!this.camposHabilitados"
                            class="grey--text"
                    >
                      aquí
                    </span>
                </small>
            </v-col>

        </v-row>
        <input type="submit" v-show="false">
    </v-form>
</template>

<script>
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import {required, between, helpers} from 'vuelidate/lib/validators'
import validEntity from "@/model/validaciones/valid.entity";
import {ID_GRUPO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import {getCertificadoPuedeBuscarPorTitulo} from "@/functions/getCertificadoPuedeBuscarPorTitulo";
import PanelBuscarPorTitular from "@/views/index/tramites-en-linea/PanelBuscarPorTitular";

const touchMap = new WeakMap();

const validadorFoja = helpers.regex('validadorFoja', /^([0-9])+(BIS|V|BISV|VBIS)?$/);
const validadorNumero = helpers.regex('validadorNumero', /^([0-9])+(BIS)?$/);

export default {
    name: "FNA",
    components: {PanelBuscarPorTitular},
    props: {

        id_tipo_registro: {default: 0},
        camposHabilitados: {
            type: Boolean,
            default: false,
        },
        habilitarDisable: {default: true},
        certificado: {default: "PRP"},
        item:{default: {numero: -1}},
    },

    data() {
        return {
            formulario: {
                id_tipo_formulario: ID_TIPO_FORMULARIO.FNA,
            },
            foja: null,
            numero: null,
            anho: null,
            buscandoNombre: false,
            errors: validEntity,
            ID_TIPO_REGISTRO: ID_TIPO_REGISTRO,
            registro: "PRP",
            itemNav: null,
            busquedaNombre: false
        }
    },

    created() {
        if(this.item.numero != -1){
            this.foja = this.item.foja;
            this.numero =this.item.numero;
            this.anho =this.item.anho;
        }
        this.busquedaNombre = getCertificadoPuedeBuscarPorTitulo(this.certificado.id_tipo_registro_FK, this.certificado.id_tipo_certificado)

        if (this.busquedaNombre && this.certificado != null) {

            let c = this.certificado;
            console.log(c)
            if (c.id_tipo_registro_FK === ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD) {
                switch (c.id_grupo_sugerencias) {
                    case ID_GRUPO.COPIAS_Y_CERTIFICADOS:
                        this.registro = "PRP"
                        break;
                    case ID_GRUPO.PRH_PROPIEDAD:
                        this.registro = "PRH";
                        break;
                    case ID_GRUPO.HIP_PROPIEDAD:
                        this.registro = "HIP";
                        break;
                    default:
                        this.registro = "PRP"
                }
            }
            console.log("registro:  ", this.registro)

            this.itemNav = {
                registro: this.registro,
                id_tipo_registro: this.id_tipo_registro
            }
        }
    },

    validations: {
        anho: {
            required,
            between: between(1937, new Date().getFullYear())
        },
        foja: {
            required,
            validadorFoja
        },
        numero: {
            required,
            validadorNumero
        }
    },
    computed: {
        errorFoja() {
            const errors = [];
            if (!this.$v.foja.$dirty) return errors;
            !this.$v.foja.required && errors.push("Ingrese una foja");
            !this.$v.foja.validadorFoja && errors.push("Ingrese una foja válida (Sólo números o números seguido de BIS, V, VBIS o BISV en mayúsculas, sin espacios)");
            return errors;
        },
        errorNumero() {
            const errors = [];
            if (!this.$v.numero.$dirty) return errors;
            !this.$v.numero.required && errors.push("Ingrese un numero");
            !this.$v.numero.validadorNumero && errors.push("Ingrese un número válido (Sólo números o números seguido de BIS en mayúsculas, sin espacios)");
            return errors;
        },


    },
    methods: {
        send() {
            console.log("send")

            if (this.$v.$invalid) {
                this.$v.$touch()
                return;
            }
            this.$emit("send", null);
        },
        volver() {
            this.buscandoNombre = false;
            this.$emit("buscandoNombre", false);
        },
        cambiarFormaBusqueda() {
            this.buscandoNombre = true;
            this.$emit("buscandoNombre", true);
        },
        delayTouch($v) {
            $v.$reset()
            if (touchMap.has($v)) {
                clearTimeout(touchMap.get($v))
            }
            touchMap.set($v, setTimeout($v.$touch, 800))
        },
        sendFormulario() {

            this.formulario.foja = this.foja
            this.formulario.numero = this.numero;
            this.formulario.anho = this.anho;
            this.$emit("setFormulario", this.formulario);
        },
        setFormulario() {
            this.formulario.foja = this.foja
            this.formulario.numero = this.numero;
            this.formulario.anho = this.anho;
            this.$emit("setBuscarFormulario", this.formulario);
        },
        seleccionarItem(item) {
            console.log("item: ", item)
            this.foja = item.foja + ""
            this.numero = item.numero + ""
            this.anho = item.anho + ""
            this.buscandoNombre = false;
            this.setFormulario()
            this.$emit("buscandoNombre", false);
        },
        //
        /*
        fojaFormatter(e) {

          //retornar si es que se presiona el enter o backspace
          if (e.keyCode === 13 || e.keyCode === 8) {
            return;
          }

          let inputTemp = this.foja ? this.foja : "";
          const position = e.target.selectionStart;

          let k = e.which || e.keyCode;

          if (k === 98 || k === 66) {
            e.preventDefault();
            inputTemp = inputTemp.substr(0, position) + "BIS" + inputTemp.substr(position);
          }
          //el código 118 corresponde a la tecla "v" y el 86 a la tecla "V"
          else if (k === 118 || k === 86) {
            e.preventDefault();
            inputTemp = inputTemp.substr(0, position) + "V" + inputTemp.substr(position);
          } else {
            e.preventDefault();
            inputTemp = inputTemp.substr(0, position) + e.key + inputTemp.substr(position);
          }

          if ((/^([0-9])+(BIS|V|BISV)?$/.test(inputTemp))) {
            this.foja = inputTemp;
          }

        },
        numeroFormatter(e) {

          //retornar si es que se presiona el enter o backspace
          if (e.keyCode === 13 || e.keyCode === 8) {
            return;
          }

          let inputTemp = this.numero ? this.numero : "";
          const position = e.target.selectionStart;

          let k = e.which || e.keyCode;

          if (k === 98 || k === 66) {
            e.preventDefault();
            inputTemp = inputTemp.substr(0, position) + "BIS" + inputTemp.substr(position);
          } else {
            e.preventDefault();
            inputTemp = inputTemp.substr(0, position) + e.key + inputTemp.substr(position);
          }

          if ((/^([0-9])+(BIS)?$/.test(inputTemp))) {
            this.numero = inputTemp;
          }

        }
        */
    },
    watch: {
        camposHabilitados() {
            if (this.camposHabilitados) {
                setTimeout(() => {
                    document.getElementById("inputFoja").focus();
                }, 400)

            }
        },
        registro() {
            console.log("IDAGRPACIONSUGERENCIA: ", this.registro)
        },
        foja(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            //mayúscula
            this.foja = newVal.toUpperCase();

            if (this.$v.$invalid) {
                this.$emit("setFormulario", null);
                return;
            }
            this.sendFormulario();

        },
        numero(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }

            //mayúscula
            this.numero = newVal.toString().toUpperCase();

            if (this.$v.$invalid) {
                this.$emit("setFormulario", null);
                return;
            }
            this.sendFormulario();
        },
        anho(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            if (this.$v.$invalid) {
                this.$emit("setFormulario", null);
                return;
            }
            this.sendFormulario();
        }
    }
}
</script>

<style scoped>

</style>
