export const cleanDatoAdicionalF2890 = (value) => {
   if(value.includes(";")){
       let imprimir = value.split(";")
       let mostrar = "";
       imprimir.map(i=>{
           mostrar = mostrar + i + ", "
       })
       return mostrar.slice(0, -2);
   }else{
       return value
   }

}

