import axios from "axios";
import qs from "qs";
import store from "@/store";
import {ID_TIPO_TRAMITE} from "@/model/tramites/tramites.entity";


/**
 * Carga todos los datos de carro almacenados en Vuex y les asigna un id_tipo_tramite en caso de no tenerlo
 * @returns Array de items del carro de compras
 */
export const obtenerCarroActual = () => {

    let carro = store.state.carro
    carro.map(c=>{
        if(c.id_tipo_tramite == null){
            c.id_tipo_tramite = ID_TIPO_TRAMITE.NO_DEFINIDO
        }
    })
    return carro;
}

/**
 * Función que sirve para actualizar el carro actual en Vuex
 * @param carro lista de documentos que se guardarán en el carro
 */
export const updateCarroActual = (carro) => {
    store.commit("updateCarro", carro)
}

export const obtenerCarroFromApi = () => {
    return new Promise((resolve, reject) => {
        axios.get(
            "/solicitudesclientes?action=obtener-carro"
        ).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error)
        });
    })
}
/**
 * Registra una lista de items de carro de compra en base de datos
 * @param listaCarro lista de documentos
 * @returns {Promise<unknown>}
 */
export const agregarAlCarroApi = (listaCarro) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes?action=agrega-carro",
            qs.stringify({
                obj_items_carro: JSON.stringify(listaCarro)
            })
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const vaciarCarroActual = () => {
    store.commit("deleteCarro");
}

export const reemplazarCarroRemoto = (carro) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes?action=actualiza-carro",
            qs.stringify({
                obj_items_carro: JSON.stringify(carro)
            })
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

/**
 * Conexión a la api para poder generar la solicitud cliente
 * @param data Debe cumplir con el formato de la clase NuevaSolicitud : {
 *     ClienteBoleta,
 *     RetiroValija,
 *     RetiroPlanos,
 *     int id_tipo_pago,
 *     ArrayList<ObservacionSolicitud>
 * }
 * @returns {Promise<unknown>} Su promesa redirecciona hacia la página de pago
 */
export const crearSolicitudCliente = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=create-solicitud-cliente",
            qs.stringify({
                obj_solicitud_cliente: JSON.stringify(data)
            })
        ).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        })
    })
}

export const continuarCompraApi = (id_carro_compra, id_tipo_pago) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=continuar-pago-carro-compras",
            qs.stringify({
                id_carro_compras: id_carro_compra,
                id_tipo_pago: id_tipo_pago
            })
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}

export const getEstadoPagoCarroApi = (id_carro) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=get-estado-pago-carro",
            qs.stringify({
                id_carro_compras: id_carro,
            })
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}

export const setPagoTransbankApi = (id_carro, token_ws) => {
    return new Promise((resolve, reject) => {
        axios.post(
            "solicitudesclientes/v2?action=set-pago-transbank",
            qs.stringify({
                id_carro_compras: id_carro,
                token_ws: token_ws
            })
        ).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}


export const getTiposPagosApi = () => {
    return new Promise((resolve, reject) => {
        axios.post("solicitudesclientes/v2?action=get-tipos-pagos").then(response => {
            if (response.data.status) {
                resolve(response.data.obj)
            } else {
                reject(response.data.code);
            }

        }).catch(error => {
            reject(error)
        })
    })
}

export const validarPagoTgrApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.post("solicitudesclientes/v2?action=verificar-pago-tgr", qs.stringify({
            id: id
        })).then(response => {
            if (response.data.status) {
                resolve(response.data.obj)
            } else {
                reject(response.data.code);
            }

        }).catch(error => {
            reject(error)
        })
    })
}

export const validarPagoTgrByCarroApi = (id) => {
    return new Promise((resolve, reject) => {
        axios.post("solicitudesclientes/v2?action=verificar-pago-tgr-by-carro", qs.stringify({
            id_carro_compras: id
        })).then(response => {
            if (response.data.status) {
                resolve(response.data.obj)
            } else {
                reject(response.data.code);
            }

        }).catch(error => {
            reject(error)
        })
    })
}
