import {crearSesionLocal, iniciarSesionFromApi} from "../usuario.repository";


export const iniciarSesionUsuario = (rut, password, token) => {

    return new Promise((resolve, reject) => {
        iniciarSesionFromApi(rut, password, token).then((response) => {
            if (response.data.status) {
                const obj = response.data.obj;
                console.log("datos de login: ", obj)
                crearSesionLocal(obj);
                resolve(obj);
            } else {
                reject({ response: response, mensaje: `Al parecer los datos ingresados no coinciden con una cuenta válida` })
            }
        }).catch((error) => {
            reject(error);
        })
    })

}

