export const ID_TIPO_FORMULARIO = {
    FNA: 1,
    ALZAMIENTO_Y_CANCELACION: 2,
    PLANOS: 11,
    ESCRITURAS_PUBLICAS: 4,
    INSCRIPCIONES: 16,
    ALZAMIENTO_PLAZO_VENCIDO: 17
}

export const bancos = [
    "Banco BICE",
    "Banco Consorcio",
    "Banco Corpbanca",
    "Banco Crédito e inversiones",
    "Banco Estado",
    "Banco Falabella",
    "Banco Internacional",
    "Banco Paris",
    "Banco Ripley",
    "Banco Santander",
    "Banco Security",
    "Banco de Chile / Edwards-Citi",
    "Banco del Desarrollo",
    "Coopeuch",
    "HSBC Bank",
    "Itaú",
    "Rabobank",
    "Tenpo Prepago",
    "Prepago Los Héroes",
    "Scotiabank",
    "Scotiabank azul",
    "Mercado Pago",
    "TAPP Caja los Andes"
];

export const cuentas = ["Corriente", "Vista", "Ahorro", "Chequera electronica"];
