<template>

  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-card-text>
        <p class="text-center font-weight-medium mb-0 py-4">
          No es posible agregar el trámite al carro de compras
        </p>
        <v-alert color="info" text outlined>
          <p class="text-center mb-0">Estimado(a) cliente(a), no es posible otorgar este certificado para la inscripción citada</p>

        </v-alert>

        <div class="text-center">
          <v-btn color="info" outlined @click="hideDialog"><v-icon left>mdi-close</v-icon>Entiendo, volver</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "ModalCertificadoNoDisponible",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>
