<template>
  <v-container class="custom-container">

    <v-row>

      <v-col v-if="carroRestringido">
        <v-alert color="info" text outlined>
          <p class="text-center mb-0">
            Estimado cliente, se ha detectado que su carro contiene una escritura pública o un Estudio de Título, para
            continuar, debe vaciar su carro
          </p>
        </v-alert>
      </v-col>

      <v-col cols="12" md="8" class="pr-md-0" v-if="!carroRestringido">

        <v-scroll-y-reverse-transition mode="out-in">
          <v-card
              key="seleccion"
              v-if="!jurisdiccionSeleccionada"
              class="mb-6"
          >
            <v-card-text>
              <h2 class="font-weight-regular mb-3">{{ mensajes.titulo_jurisdiccion }}</h2>
              <v-divider></v-divider>
              <div class="d-flex px-0" style="font-size: 15px !important; cursor: pointer"
                   @click="seleccionarJurisdiccion()">
                <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-checkbox-blank-outline</v-icon>
                <p class="my-3 font-weight-medium pl-1" style="justify-content: left !important; ">
                  <!--                <span> La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera</span>-->
                  <span> {{ mensajes.jurisdiccion_ok }}</span>
                </p>
              </div>
              <div class="d-flex px-0" style="font-size: 15px; cursor: pointer" @click="jurisdiccion.dialog = true">
                <v-icon color="info" small class="pr-2" style="font-size: inherit">mdi-checkbox-blank-outline</v-icon>
                <p class="my-0 font-weight-regular pl-1" style="justify-content: left !important;">
                  <!--               <span> La propiedad se encuentra en otra comuna</span>-->
                  <span>{{ mensajes.otra_jurisdiccion }}</span>
                </p>
              </div>


            </v-card-text>
          </v-card>

          <v-card
              key="confirmado"
              v-else
              class="mb-6"
          >
            <v-card-text class="py-0">
              <div class="d-flex px-4" style="font-size: 15px !important; cursor: pointer">
                <v-icon style="font-size: inherit" class="pr-2" color="success">mdi-check</v-icon>
                <p class="my-3 success--text pl-1" style="justify-content: left !important; ">

                  <!--                <span> La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera</span>-->
                  <span> {{ mensajes.jurisdiccion_ok }}</span>
                </p>
              </div>
            </v-card-text>
          </v-card>
        </v-scroll-y-reverse-transition>


        <v-card class="px-4">
          <v-card-title class="pb-1">
            Estudio de título
          </v-card-title>
          <v-card-text>
            <p class="mb-2">Ingrese los datos correspondientes al <span class="font-weight-bold">título requerido</span>
            </p>

            <v-row>
              <v-col class="pb-0">
                <!--                    :key="habilitarFormulario"-->
                <formulario-fna
                    :key="keyFormularioFna"
                    @send="validarRef()"
                    :id_tipo_registro="certificado.id_tipo_registro_FK"
                    :campos-habilitados="!mostrarDocumentosTentativos && jurisdiccionSeleccionada"
                    :certificado="certificado"
                    @setFormulario="puedeValidar($event)"
                    @buscandoNombre="buscarPorNombre=$event"
                />
              </v-col>
              <v-col
                  v-if="!buscarPorNombre"
                  class="pt-md-6 pb-0"
                  cols="12"
                  md="3"
              >

                <validador-indices
                    ref="validador"
                    :puede-validar-indice="puedeValidarIndice"
                    :habilitar-boton="habilitarFormulario"
                    :certificado="certificado"
                    :data-formulario="dataFormulario"
                    @corregirFoja="corregirFoja($event)"
                    @confirmarIndice="confirmarIndice"
                />

                <div class="text-right">
                  <v-btn text x-small color="info" :disabled="!mostrarDocumentosTentativos"
                         @click="limpiarFormularioFna">Limpiar formulario
                  </v-btn>
                </div>

              </v-col>

              <v-col class="text-right pt-0" cols="12">

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-fade-transition>
          <div class="mt-3" v-if="mostrarDocumentosTentativos">
            <lista-copias-certificados-titulo :otras-copias="otras_copias"
                                              :certificados_utilizados="certificados_utilizados"
                                              :titulo="titulo"
                                              :data-formulario="dataFormulario"
                                              :titulos_anteriores="titulos_anteriores"></lista-copias-certificados-titulo>
          </div>
        </v-fade-transition>
      </v-col>
      <v-col sm="12" md="4" class=" pl-sm-6">
        <InformacionDocumento :certificado="certificado" v-if="!load.certificado"></InformacionDocumento>
      </v-col>
    </v-row>

    <simple-modal :verDialog="jurisdiccion.dialog" @ocultarDialog="jurisdiccion.dialog = false">
      <div>
        <h3 class="text-center pb-3">Otras comunas</h3>
        <v-divider></v-divider>
        <p class="my-3 px-4 text-center">
          Si la comuna no es parte de la jurisdicción del Conservador de Bienes Raíces de La Serena debe revisar el portal de la <a
            href="http://www.conservadores.cl/oficios_registrales/" target="_blank">Corporación Chilena de Estudios de
          Derecho Registral</a> para realizar su trámite.
        </p>
        <v-btn outlined block color="info" @click="jurisdiccion.dialog = false">Volver</v-btn>
      </div>
    </simple-modal>
  </v-container>
</template>

<script>
import registrosEntity, {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import InformacionDocumento from "@/components/SolicitudDocumentos/InformacionDocumento";
import ValidadorIndices from "@/components/Tramites/ValidadorIndices";
import FormularioFna from "@/components/SolicitudDocumentos/Formularios/FormularioFna";
import tituloEntity from "@/model/titulo/titulo.entity";
import ListaCopiasCertificadosTitulo from "@/views/index/estudio-titulo/ListaCopiasCertificadosTitulo";
import registros from "@/model/registros/registros.entity";
import SimpleModal from "@/components/SimpleModal";
// import {obtenerCarroActual} from "@/model/carro/carro.repository";

export default {
  name: "EstudioTituloView",
  components: {SimpleModal, ListaCopiasCertificadosTitulo, ValidadorIndices, InformacionDocumento, FormularioFna},
  data() {
    return {
      mensajes: {
        jurisdiccion_ok: "La propiedad se encuentra ubicada en la comuna de La Serena o La Higuera.",
        otra_jurisdiccion: "La propiedad se encuentra en otra comuna.",
        titulo_jurisdiccion: "Seleccione donde se encuentra ubicada la propiedad"
      },
      jurisdiccion: {
        switch: false,
        remarcado: false,
        dialog: false
      },
      jurisdiccionSeleccionada: false,
      certificado: {
        dias_entrega: 1,
        id_tipo_certificado: 20000, //simular para mostrar el documento representativo
        id_tipo_registro_FK: ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD,
        informacionCertificado: {
          informacionCertificado: Object,
          descripcion: "Es una copia simple del título del bien raíz, no señala vigencia. Generalmente es solicitada para estudiar los títulos anteriores previamente a una venta.",
          otras_descripciones: [{
            clave: "Beneficiarios",
            valor: "Persona natural o jurídica, propietaria o no de un inmueble, respecto del cual se desee obtener un documento autorizado por el CBRLS."
          }],
        },
        precio: 5300,
        tipo_certificado: "Estudio de título 10 Años",
        tipo_registro: "Inscripción de Comercio",
        vigencia: -1

      }, //generar un certificado por defecto solo para la vista previa
      load: {
        certificado: false,

      },
      keyFormularioFna: 0,
      buscarPorNombre: false,
      puedeValidarIndice: false,
      habilitarFormulario: true,
      dataFormulario: null,
      mostrarDocumentosTentativos: false,
      titulo: null,
      titulos_anteriores: [],
      certificados_utilizados: {
        copia_autorizada: null,
        copia_con_vigencia: null
      },
      otras_copias: {
        copias_autorizadas: 0,
        copias_hipotecas: 0,
        copias_prohibiciones: 0,
        copias_escrituras: []
      },
      carroRestringido: false
    }
  },
  mounted() {
    // let carro = obtenerCarroActual()
    //
    // carro.map(item => {
    //   if (item.id_tipo_certificado_FK == ID_TIPO_CERTIFICADO.ESCRITURAS_PUBLICAS || item.id_tipo_certificado_FK == ID_TIPO_CERTIFICADO.ESTUDIO_TITULO) {
    //     console.log("contiene estudio titulo o escritura publica")
    //     this.carroRestringido = true;
    //   }
    // })
  },
  created() {

    /**
     * Al crearse la página, carga todos los precios de los documentos desde backend
     */

    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_CON_VIGENCIA).then(res => {
      this.certificados_utilizados.copia_con_vigencia = res;
    })
    registrosEntity.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA).then(res2 => {
      this.certificados_utilizados.copia_autorizada = res2;
    })
    registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA).then(r => {
      this.certificados_utilizados.copia_hipoteca = r
    })
    registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION).then(r => {
      this.certificados_utilizados.copia_prohibicion = r
    })
    registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ESTUDIO_TITULO, ID_TIPO_CERTIFICADO.ESTUDIO_TITULO).then(r => {
      this.certificados_utilizados.estudio_titulo = r;
      this.certificado = r;
    })
    registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD, ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES).then(r => {
      this.certificados_utilizados.gp = r;
    })
    registros.getFromTiposRegistrosTiposCertificados(ID_TIPO_REGISTRO.ARCHIVERO_JUDICIAL, ID_TIPO_CERTIFICADO.COPIA_ESCRITURA_ESTUDIO_TITULO).then(res => {
      this.certificados_utilizados.escrituras_publicas = res;
    })

  },
  methods: {
    seleccionarJurisdiccion() {
      setTimeout(() => {
        this.jurisdiccionSeleccionada = true
        this.habilitarFormulario = true;

        if (this.formularioAlzamientoPrecargado) {
          this.mostrarDocumentosTentativos = true;
        }
      }, 200)
    },
    limpiarFormularioFna() {

      console.log("intenta limpiar el formulario")
      this.keyFormularioFna++;
      this.puedeValidarIndice = false;
      this.habilitarFormulario = true;
      this.mostrarDocumentosTentativos = false;
    },
    validarRef() {
      this.$refs.validador.validarIndice();
    },
    puedeValidar(data) {
      this.puedeValidarIndice = data != null;
      this.dataFormulario = data;
    },

    /**
     * Al confirmar el índice, deshabilita los ínput para ingresar foja numero y año, luego muestra la lista de los certificados
     * disponibles
     *
     */
    confirmarIndice() {

      tituloEntity.getEstudioTitulo(this.dataFormulario.numero, this.dataFormulario.anho).then(r => {
        console.log("titulo obtenido: ")

        if (r.obj == null) {
          console.log("No se ha encontrado el título")
        } else {
          console.log(r.obj)
          this.titulo = r.obj.titulo;
          this.otras_copias.copias_autorizadas = r.obj.copias_autorizadas
          this.otras_copias.copias_hipotecas = r.obj.copias_hipotecas
          this.otras_copias.copias_prohibiciones = r.obj.copias_prohibiciones
          this.otras_copias.copias_escrituras = r.obj.copias_escritura
          this.titulos_anteriores = r.obj.indices_anteriores;
          console.log("se asigno el titulo: ", this.titulo)
        }
        this.habilitarFormulario = false;
        this.mostrarDocumentosTentativos = true;
      })
    },

    /**
     * Esta funcion se utiliza para corregir la foja cuando el cliente la ingresa mal, la nueva foja se
     * obtiene desde el backend en base a los datos registrados en scbr.titulos
     * @param datosIndice
     */
    corregirFoja(datosIndice) {
      this.carillas_encontradas = 0;
      this.precio_adicional_carillas = 0;

      console.log("llegan los dat")

      if (datosIndice != null) {
        this.dataFormulario.foja = datosIndice.foja;
        this.carillas_encontradas = datosIndice.numero_carillas;
        this.precio_adicional_carillas = datosIndice.precio_carillas
      }
      this.confirmarIndice()
    },
  },
}
</script>

<style scoped>

</style>
