import {getHipotecasYGravamenesTituloApi} from "@/model/titulo/titulo.repository";

const getHipotecasYGravamenesTitulo = (numero, anho) => {



    return getHipotecasYGravamenesTituloApi(numero, anho)
}

export default getHipotecasYGravamenesTitulo;
