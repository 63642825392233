import getCategoriasRegistrosTramitesEnLinea
    from '@/model/registros/usecases/get-categorias-registros-tramites-en-linea'
import getTiposRegistrosFromCategoria from "@/model/registros/usecases/get-tipos-registros-from-categoria";
import getCertificadoTramitesEnLinea from "@/model/registros/usecases/get-certificado-tramites-en-linea";
import toCase from "@/model/registros/usecases/text-convert";
import isSerie, {validarIndice} from "@/model/registros/usecases/is-serie";
import getAllCertificadosBuscador from "@/model/registros/usecases/get-all-certificados-buscador";
import getCertificadoLitigios from "@/model/registros/usecases/get-certificados/get-certificado-litigios";
import getImagenCertificado from "@/model/registros/usecases/get-imagen-certificado";
import getAllDataRegistros from "@/model/registros/usecases/get-all-data-registros";
import getCertificadosByTipoRegistro
    from "@/model/registros/usecases/get-certificados/get-certificados-by-tipo-registro";
import getFromTiposRegistrosTiposCertificados
    from "@/model/registros/usecases/get-certificados/get-from-tipos-registros-tipos-certificados";

export const ID_CATEGORIA_REGISTRO = {
    PROPIEDAD: 1,
    COMERCIO: 2,
    AGUAS: 3,
    MINAS: 4,
}

export const ID_TIPO_REGISTRO = {
    REGISTRO_DE_PROPIEDAD: 1,
    AGUAS: 2,
    HIPOTECA_PROPIEDAD: 3,
    PLANOS_PROPIEDAD: 5,
    PLANOS_LEY: 6,
    COMERCIO: 7,
    ALZAMIENTO_Y_CANCELACION: 15,
    ESCRITURAS_PUBLICAS: 20,
    INSCRIPCION_PROPIEDAD: 16,
    CONSTITUCION_DE_SOCIEDAD: 17,
    INSCRIPCION_COMERCIO: 17,
    ARCHIVERO_JUDICIAL: 21,
    ESTUDIO_TITULO: 24,
    ALZAMIENTO_SERVIU_PLAZO_VENCIDO: 25
}

export const ID_TIPO_CERTIFICADO = {
    COPIA_AUTORIZADA: 1,
    CERTIFICADO_DOMINIO_VIGENTE: 3,
    COPIA_CON_VIGENCIA: 2,
    CERT_HIPOTECAS_GRAVAMENES: 4,
    CERTIFICADO_VIGENCIA_SOCIEDAD: 5,
    LITIGIOS: 8,
    CANCELACION_HIPOTECA: 10,
    CANCELACION_HIPOTECA_SERVIU: 11,
    ALZAMIENTO_PROHIBICIONES: 12,
    ALZAMIENTO_PROHIBICIONES_SERVIU: 13,
    INSCRIPCION_DE_PROPIEDAD: 14,
    INSCRIPCION_DE_HIPOTECA: 18,
    INSCRIPCION_DE_PROHIBICION: 24,
    CERTIFICACIONES: 35,
    INSCRIPCION_DE_COMERCIO: 39,
    ESCRITURAS_PUBLICAS: 59,
    COPIA_AUTORIZADA_HIPOTECA: 79,
    COPIA_AUTORIZADA_PROHIBICION: 80,
    CERT_BIEN_FAMILIAR:83,
    ALZAMIENTO_GP_UNA_PRP: 90,
    MODIFICACION_DE_SOCIEDAD: 94,
    ALZAMIENTO_SERVIU_PLAZO_VENCIDO: 95,
    CERT_HIPOTECAS_GRAVAMENES_ALZAMIENTO_SERVIU: 96,
    INSCRIPCION_DE_COMERCIO_CON_NOTA_MARGINAL: 97,
    MODIFICACION_DE_SOCIEDAD_CON_NOTA_MARGINAL: 98,
    INCRIPCION_PROPIEDAD_CON_COPIA_VIGENCIA: 101,
    ESTUDIO_TITULO: 104,
    ALZAMIENTO_PLAZO_VENCIDO: 105,
    COPIA_ESCRITURA_ESTUDIO_TITULO: 106,
    COPIA_CON_VIGENCIA_O_DOMINIO_VIGENTE: 20000,

}

export const ID_GRUPO = {
    COPIAS_Y_CERTIFICADOS: 2,
    PLANOS_LEY: 3,
    ALZAMIENTO_Y_CANCELACION: 4,
    PLANOS_PROPIEDAD: 6,
    PRH_PROPIEDAD: 7,
    HIP_PROPIEDAD: 8,
    ESCRITURAS_PUBLICAS: 9

}

export const ID_AGRUPACION_DOCUMENTO = {
    COPIAS: 2,
    CERTIFICADOS: 3,
    ALZAMIENTOS: 4,
    INSCRIPCIONES: 5,
    ALZAMIENTOS_SERVIU: 6,
    OTROS: 7
}

export const ID_AREA_AGRUPACION = {

    ALZAMIENTO_Y_CANCELACION: 3,
    PLANOS: 14,
    ESCRITURAS_PUBLICAS: 31,
    INSCRIPCION_PROPIEDAD: 32,
    INSCRIPCION_COMERCIO: 33,
    MODIFICACION_SOCIEDAD: 34,
    ALZAMIENTO_SERVIU: 35,
    CONSTITUCION_DE_SOCIEDAD: 36,
    ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO: 38,
    COPIAS_PROPIEDAD: 1,
    COPIAS_COMERCIO: 4,
    COPIAS_AGUAS: 6,

}

export const ID_TIPO_INDICE = {
    PROPIEDAD: 1,
    PROHIBICION: 2
}

export const PRECIO = {
    CERTIFICADO_LITIGIOS: 2300
}


export const LITIGIO_PREDETERMINADO = false;

const registrosEntity = {

    //
    getCategoriasRegistros: getCategoriasRegistrosTramitesEnLinea,
    getTiposRegistrosFromCategoria: getTiposRegistrosFromCategoria,
    getCertificadoTramitesEnLinea: getCertificadoTramitesEnLinea,
    isSerie: isSerie,
    getAllCertificadosBuscador: getAllCertificadosBuscador,
    toCase: toCase,
    ID_GRUPO: ID_GRUPO,
    ID_TIPO_REGISTRO: ID_TIPO_REGISTRO,
    ID_TIPO_CERTIFICADO: ID_TIPO_CERTIFICADO,
    getCertificadoLitigios: getCertificadoLitigios,
    getImagenCertificado: getImagenCertificado,
    getAllDataRegistros,
    getCertificadosByTipoRegistro,
    getFromTiposRegistrosTiposCertificados,
    validarIndice
}

export const idToIcon = (idRegistro) => {
    switch (idRegistro) {
        case ID_CATEGORIA_REGISTRO.PROPIEDAD:
            return "mdi-home";
        case ID_CATEGORIA_REGISTRO.COMERCIO:
            return "mdi-store";
        case ID_CATEGORIA_REGISTRO.AGUAS:
            return "mdi-water-pump";
        case ID_CATEGORIA_REGISTRO.MINAS:
            return "mdi-pickaxe";
    }
}

export const idToImage = (idRegistro) => {
    switch (idRegistro) {
        case ID_CATEGORIA_REGISTRO.PROPIEDAD:
            return "propiedad";
        case ID_CATEGORIA_REGISTRO.COMERCIO:
            return "comercio";
        case ID_CATEGORIA_REGISTRO.AGUAS:
            return "aguas";
        case ID_CATEGORIA_REGISTRO.MINAS:
            return "minas";
    }
}

export default registrosEntity;
