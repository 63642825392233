<template>
    <v-dialog max-width="500" v-model="ver" persistent>
        <v-card class="text-center pa-4">
            <h3>Verifique su foja</h3>
            <h4 class="mt-4">No hemos encontrado su foja ¿Quiso decir
                {{ sociedad.foja + " - " + sociedad.numero + " - " + sociedad.anho }}?</h4>
            <div class="d-flex justify-center mt-4">

                <h4>{{ sociedad.nombre_sociedad }}</h4>
            </div>
            <div class="d-flex justify-space-between mt-4">
                <v-btn
                    @click="hideDialog()"
                    text
                    color="error"
                >NO ES MI SOCIEDAD</v-btn>
                <v-btn
                    @click="cambiarFoja()"
                    text
                    color="success"
                >SÍ, ES MI SOCIEDAD</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalCambioDeFojaSociedad",
    props: {
        time: {default: null},
        tipo: {default: "info"},
        verDialog: {default: false},
        sociedad: {default: null},
    },
    data() {
        return {
            ver: false
        }
    },

    watch: {
        verDialog() {
            this.ver = this.verDialog;
        },

        ver(newVal) {
            if (!newVal) {
                this.$emit("ocultarDialog", false);
            }
        },


    },

    methods: {
        cambiarFoja() {
            this.$emit("cambiarFoja", true);
            this.$emit("ocultarDialog", false);
        },
        hideDialog() {
            this.$emit("cambiarFoja", false);
            this.$emit("ocultarDialog", false);
        },
    }
}
</script>
<style scoped>
h4{
   font-weight:normal ;
}
</style>