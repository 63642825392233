import {
    getHipotecasYGravamenesTramitesTituloApi
} from "@/model/titulo/titulo.repository";

/**
 * Este método va al backend a buscar las hipotecas, prohibiciones y prohibiciones por plazo vencido
 * @param foja
 * @param numero
 * @param anho
 * @param certificado
 * @returns {Promise<unknown>}
 */
const getHipotecasYGravamenesTramitesTitulo = (foja, numero, anho, certificado) => {

    return new Promise((resolve, reject) => {
        let indice = `${foja}-${numero}-${anho}`

        getHipotecasYGravamenesTramitesTituloApi(indice, certificado.id_tipo_certificado).then(res => {
            console.log("datos encontrados: ", res)
            if (res == null) {
                resolve(null);
            } else {
                let cert = {
                    ...certificado,
                    numero_copias: 1,
                    vigencia: -1
                }

                let respuesta = {
                    ...res,
                    certificados: [cert]
                }
                resolve(respuesta);
            }


        }).catch(e => {
            reject(e)
        })
    })

}

export default getHipotecasYGravamenesTramitesTitulo;
