<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <p class="font-weight-bold text-h6 info--text text-center pt-3">Datos registrales no encontrados</p>

        <p class="text-center">Los datos citados no se hacen corresponder con algún título de nuestros registros</p>
        <div class="text-center">
          <v-btn @click="hideDialog" color="info" outlined>
            <v-icon left>mdi-close</v-icon>
            Consultar otro índice
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalIndiceNoEncontrado",
  props: {
    time: {default: null},
    tipo: {default: "info"},
    verDialog: {default: false}
  },
  data() {
    return {
      ver: false,
      checkbox: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.checkbox = false;
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    confirmar() {
      this.$emit("confirmarEscrituraPublica", null);
    }
  }
}
</script>

<style scoped>

</style>
