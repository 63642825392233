import {obtenerCarroActual} from "@/model/carro/carro.repository";
import {LIMITE_ELEMENTOS_CARRO} from "@/model/carro/carro.entity";
import {ID_TIPO_REGISTRO} from "../../registros/registros.entity";

/**
 * Función que evalúa si el carro de compras se encuentra lleno
 * @param documento Documento que se insertará
 * @returns {boolean} true si puede insertar, false si no
 */
const isLimiteExedido = (documento) => {

    if (documento.id_tipo_registro_FK === ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION) {
        return false
    }

    let carro_actual = obtenerCarroActual();

    let elementos_similares = carro_actual.filter(f => {
        return f.id_tipo_registro_FK === documento.id_tipo_registro_FK && f.id_area_agrupacion === documento.id_area_agrupacion;
    })
    return elementos_similares.length >= LIMITE_ELEMENTOS_CARRO
}

export default isLimiteExedido
