<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card>
      <v-alert
          border="left"
          colored-border
          type="error"
          class="mb-0"
          :icon="false"
      >

        <p class="text-center mb-0">
          No se pudo agregar al carro porque existen documentos que lo estan bloqueando
        </p>
      </v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modalEstudioTituloRestringido",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    }
  },
  data() {
    return {
      ver: false
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>
