import registrosEntity from "@/model/registros/registros.entity";
import {obtenerCarroActual} from "@/model/carro/carro.repository";


const getCarroActualCategorizado = () => {

    let carro = [];
    let lista_carro = obtenerCarroActual();
    let lista_tipos_registros = [];
    lista_carro.map(item => {
        let id_tipo_registro = item.id_tipo_registro_FK;
        let grupo = item.id_area_agrupacion

        let obj = {
            id: id_tipo_registro,
            grupo: grupo
        }
        let cat = lista_tipos_registros.filter(lis => {
            return lis.id === obj.id && lis.grupo === obj.grupo
        })

        if (cat.length == 0) {
            lista_tipos_registros.push(obj)
        }

    })


    lista_tipos_registros.map(ob => {
        let subtotal = 0;

        let certificados_grupo = lista_carro.filter(f => {
            return f.id_tipo_registro_FK == ob.id && f.id_area_agrupacion == ob.grupo
        })
        let lista_areas = [];

        certificados_grupo.map(cert => {
            if (lista_areas.indexOf(cert.id_area_agrupacion) !== -1) {
                lista_areas.push(cert.id_area_agrupacion);
            }
        })

        let id_agrupaciones = [];
        let grupos_documentos = []

        certificados_grupo.map(grupo => {
            subtotal += grupo.precio * grupo.numero_copias;
            if (id_agrupaciones.indexOf(grupo.id_area_agrupacion) === -1) {
                id_agrupaciones.push(grupo.id_area_agrupacion)
            }
        })

        id_agrupaciones.map(id => {
            let grupo = certificados_grupo.filter(f => {
                return f.id_area_agrupacion === id;
            })

            let area = {
                id_area_agrupacion: grupo[0].id_area_agrupacion,
                area_agrupacion: grupo[0].area_agrupacion,
                subtotal: subtotal,
                certificados: grupo
            }
            grupos_documentos.push(area);

        })

        let grupo = {
            tipo_registro: certificados_grupo[0].tipo_registro,
            id_tipo_registro: certificados_grupo[0].id_tipo_registro_FK,
            subtotal: subtotal,
            grupos_documentos: grupos_documentos
        }

        if (grupo.id_tipo_registro === registrosEntity.ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION) {
            grupo.alzamiento = certificados_grupo[0].alzamiento
        }
        if (certificados_grupo[0].datos_adicionales != null){
            // if (grupo.id_tipo_registro === registrosEntity.ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS || grupo.id_tipo_registro === registrosEntity.ID_TIPO_REGISTRO.INSCRIPCION_PROPIEDAD || grupo.id_tipo_registro === registrosEntity.ID_TIPO_REGISTRO.INSCRIPCION_COMERCIO) {
            grupo.datos_adicionales = certificados_grupo[0].datos_adicionales;
    }

        console.log("lista carro",lista_carro)
    carro.push(grupo);
}
)

return carro;
}

export default getCarroActualCategorizado;
