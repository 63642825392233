import getCarro from "@/model/carro/usecases/get-carro";
import agregarAlCarro from "@/model/carro/usecases/agregar-al-carro";
import getResumen from "@/model/carro/usecases/get-resumen";
import validarCarros from "@/model/carro/usecases/validar-carros";
import reemplazarCarro from "@/model/carro/usecases/reemplazar-carro-remoto";
import getCarroActual from "@/model/carro/usecases/get-carro-actual";
import updateCarro from "@/model/carro/usecases/update-carro";
import getAlzamientoCarro from "@/model/carro/usecases/get-alzamiento-carro";
import verificarTipoAlzamientoCarro from "@/model/carro/usecases/verificar-tipo-alzamiento-carro";
import getCarroActualCategorizado from "@/model/carro/usecases/get-carro-actual-categorizado";
import isFormValija from "@/model/carro/usecases/is-form-valija";
import isFormPlanos from "@/model/carro/usecases/is-form-planos";
import validarFormularioPago from "@/model/carro/usecases/validar-formulario-pago";
import continuarCompra from "@/model/carro/usecases/continuar-compra";
import getEstadoPagoCarro from "@/model/carro/usecases/get-estado-pago-carro";
import verificarSolicitudesDeCarroPagadas from "@/model/carro/usecases/verificar-solicitudes-de-carro-pagadas";
import getDatosAdicionalesCertificado from "@/model/carro/usecases/get-datos-adicionales-certificado";
import setPagoTransbank from "@/model/carro/usecases/set-pago-transbank";
import getTiposPagos from "@/model/carro/usecases/get-tipos-pagos";
import agregarAlCarroInscripciones from "@/model/carro/usecases/agregar-al-carro-inscripciones";
import validarPagoTgr from "@/model/carro/usecases/validar-pago-tgr";
import validarPagoTgrByCarro from "@/model/carro/usecases/validar-pago-tgr-by-carro";


export const LIMITE_ELEMENTOS_CARRO = 20;

export const CARRO_CODES = {
    CONSERVAR_CARRO_ACTUAL: 1,
    CONSERVAR_CARRO_REMOTO: 2,
    ELEGIR_CARRO: 3,
    DOCUMENTOS_AGREGADOS: 4,
    DOCUMENTOS_REPETIDOS: 5,
    LIMITE_CARRO_ALCANZADO: 6
}

export const ID_TIPO_RETIRO = {
    PRESENCIAL: 1,
    CHILEXPRESS: 2,
    VALIJA_ELECTRONICA: 3
}

export const ID_TIPO_PAGO = {
    DISPONIBLES: 4,
    NO_INFORMADO: 0,
    KHIPU: 1,
    PRESENCIAL: 2,
    KHIPU_WEB: 3,
    REDCOMPRA: 4,
    TGR: 5,
    POR_COTIZAR: 6
}

export const PRECIO_CHILEXPRESS = 3500;

const carroEntity = {
    obtenerCarro: getCarro,
    agregarAlCarro: agregarAlCarro,
    obtenerResumen: getResumen,
    validarCarros: validarCarros,
    reemplazarCarro: reemplazarCarro,
    obtenerCarroActual: getCarroActual,
    updateCarro: updateCarro,
    obtenerAlzamiento: getAlzamientoCarro,
    verificarTipoAlzamiento: verificarTipoAlzamientoCarro,
    obtenerCarroActualCategorizado: getCarroActualCategorizado,
    isFormValija: isFormValija,
    isFormPlanos: isFormPlanos,
    validarFormularioPago: validarFormularioPago,
    continuarPagoCarro: continuarCompra,
    CODE: CARRO_CODES,
    getEstadoPagoCarro: getEstadoPagoCarro,
    verificarSolicitudesDeCarroPagadas: verificarSolicitudesDeCarroPagadas,
    getDatosAdicionalesCertificado,
    setPagoTransbank: setPagoTransbank,
    getTiposPagos,
    agregarAlCarroInscripciones,
    validarPagoTgr,
    validarPagoTgrByCarro
}

export default carroEntity;
