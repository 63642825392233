<template>
  <div style="width: 100%">

    <v-btn
        height="40"
        type="button"
        block
        color="info"
        :disabled="!puedeValidarIndice || !habilitarBoton || validandoIndice"
        :loading="validandoIndice"
        @click="validarIndice"
    >
      Buscar
    </v-btn>

    <modal-serie
        :ver-dialog-serie="dialogSerie"
        :serie="serie"
        @cerrarModalSerie="dialogSerie = false"
        @recargarBusqueda="recargarModalSerie()"
        @agregarDocumentos="corregirFoja($event)"
    />
    <modal-certificado-no-disponible
        :ver-dialog="verModalCertificadoNoDisponible"
        @ocultarDialog="verModalCertificadoNoDisponible = false"
    />
    <modal-indice-no-encontrado
        :ver-dialog="verModalTituloNoEncontrado"
        @ocultarDialog="verModalTituloNoEncontrado = false"
    />
    <modal-cambio-de-foja-sociedad
        :ver-dialog="verModalCambiarFoja"
        :sociedad="sociedad"
        @ocultarDialog="verModalCambiarFoja = false"
        @cambiarFoja="cambioDeFoja($event)"
    ></modal-cambio-de-foja-sociedad>
  </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import registros, {ID_TIPO_REGISTRO, ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";
import ModalSerie from "@/components/SolicitudDocumentos/modals/ModalSerie";
import tituloEntity from "@/model/titulo/titulo.entity";
// eslint-disable-next-line no-unused-vars
import getIndiceTitulo from "@/model/titulo/usecases/get-indice-titulo";
import ModalCertificadoNoDisponible from "@/views/index/tramites-en-linea/ModalCertificadoNoDisponible";
import ModalIndiceNoEncontrado from "@/components/Tramites/ModalIndiceNoEncontrado";
import ModalCambioDeFojaSociedad from "@/components/Tramites/ModalCambioDeFojaSociedad.vue";

/**
 * Componente que se encarga de validar si existe o no el índice que recibe en sus props
 */

export default {
  name: "ValidadorIndices",
  components: {ModalCambioDeFojaSociedad, ModalIndiceNoEncontrado, ModalCertificadoNoDisponible, ModalSerie},
  props: {
    puedeValidarIndice: {
      default: true
    },
    dataFormulario: {
      type: Object,
      default: () => {
      }
    },
    certificado: {
      type: Object,
      default: () => {
      }
    },
    habilitarBoton: {default: true}
  },
  data() {
    return {
      escritura: null,
      dialogSerie: false,
      fojaCorrecta: null,
      serie: null,
      validandoIndice: false,
      verModalCertificadoNoDisponible: false,
      verModalTituloNoEncontrado: false,
      naturaleza: String,
      verModalCambiarFoja: false,
      sociedad: null,
    }
  },
  methods: {
    cambioDeFoja(cambiarFoja) {
      if (cambiarFoja === true) {
        //this.dataFormulario.foja = this.sociedad.foja
        this.$emit("cambiaFoja", this.sociedad.foja);
        this.$emit("noMostrarDocumentos", true);
      } else {
        this.$emit("noMostrarDocumentos", true);
      }
    },

    validarIndice() {
      console.log("se ejecuto una busqueda, this.certificado: ", this.certificado)
      if (this.certificado.id_tipo_registro_FK == ID_TIPO_REGISTRO.COMERCIO) {
        console.log("serie:", this.dataFormulario)

        this.$emit("noMostrarDocumentos", false);
        let indice = this.dataFormulario.anho.toString() + "-" + this.dataFormulario.numero
        tituloEntity.getNaturalezaComercio(indice).then(res => {
          console.log(res)
          if (res.data.obj != null) {

            this.sociedad = res.data.obj;
            this.naturaleza = this.sociedad.naturaleza.toString();
            console.log("naturaleza", this.naturaleza);
            let contieneConstitucion = false
            let constitucionBoolean = this.naturaleza.toLowerCase().includes('constitución');
            let constitucionBoolean2 = this.naturaleza.toLowerCase().includes('constitucion');
            if (constitucionBoolean === true || constitucionBoolean2 === true) {
              contieneConstitucion = true;
            }
            if (this.dataFormulario.foja != this.sociedad.foja) {
              console.log(this.dataFormulario.foja, "no son iguales", this.sociedad.foja);
              this.verModalCambiarFoja = true;

            } else {
              this.$emit("noMostrarDocumentos", true);
            }

            this.$emit("contieneConstitucion", contieneConstitucion);
            this.$emit("naturaleza",this.naturaleza);

          }else{
            console.log("no tiene naturaleza")
            this.$emit("contieneConstitucion",true);
            this.$emit("noMostrarDocumentos", true);
          }
        })
      }

      /**
       * Si es del tipo registro indicado, validará los datos ingresados en el índice, si no, pasará directamente al
       * siguiente paso.
       *
       */
      if (this.certificado.id_tipo_registro_FK == ID_TIPO_REGISTRO.REGISTRO_DE_PROPIEDAD) {



        this.validandoIndice = true;

        let indice = `${this.dataFormulario.foja}-${this.dataFormulario.numero}-${this.dataFormulario.anho}`;

        let id_cert = this.certificado.id_tipo_certificado

        /**
         * Si es Hipoteca o Prohibición, buscará en SCBR si es que existe para poder agregarla al carro, en caso de
         * no existir, se le mostrará un modal informativo al cliente
         *
         */
        console.log("es registro propiedad")
        if (id_cert === ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_HIPOTECA || id_cert === ID_TIPO_CERTIFICADO.COPIA_AUTORIZADA_PROHIBICION) {
          tituloEntity.getHipotecasYGravamenesTramitesTitulo(this.dataFormulario.foja, this.dataFormulario.numero, this.dataFormulario.anho, this.certificado).then(res => {

            this.validandoIndice = false;
            this.serie = null;
            this.dialogSerie = true;

            if (res == null) {
              this.dialogSerie = true;
              return;
            }

            if (res.indice_correcto) {
              this.serie = null;
              this.corregirFoja();
            }

            /*
            this.validandoIndice = false;
            if (res == null) {
              this.dialogSerie = true;
              return;
            }

            this.fojaCorrecta = res.foja
            this.serie = res;
            if (res.indice_correcto) {
              this.corregirFoja();
            } else {
              this.dialogSerie = true
            }
            */

          })

        }
        /**
         * Si no es de los tipos de certificados que se les necesita cargar sus hipotecas y prohibiciones, solo valida
         * el índice con SCBR
         */
        else {

          tituloEntity.getEscrituraTituloFNA(this.dataFormulario.foja, this.dataFormulario.numero, this.dataFormulario.anho).then(res => {
            console.log("escritura traida ", res)
            this.escritura = res.obj
            this.$emit("escritura", this.escritura);

          tituloEntity.getIndiceTitulo(indice).then(res => {
            console.log("respuesta backend", res.obj);

            if (res.obj != null) {
              let info = res.obj;
              if (res.obj == null) {
                this.verModalTituloNoEncontrado = true;
                return;
              }
              let isInLista;

              isInLista = info.certificados.find(cert => {
                return cert.id_tipo_certificado == this.certificado.id_tipo_certificado && cert.id_tipo_registro_FK == this.certificado.id_tipo_registro_FK
              })

              if (isInLista != null) {
                this.validandoIndice = false;
                this.fojaCorrecta = res.obj.foja
                this.serie = res.obj;
                if (res.obj.indice_correcto) {
                  this.corregirFoja();
                } else {
                  this.dialogSerie = true
                }
              } else {
                console.log("tipo certificado no admitido para el indice")
                this.verModalCertificadoNoDisponible = true;
                this.validandoIndice = false;
              }


            } else {
              this.serie = null;
              this.dialogSerie = true;
              this.validandoIndice = false;
            }

          })
          })
        }

      } else if (this.certificado.id_tipo_registro_FK == ID_TIPO_REGISTRO.ESTUDIO_TITULO) {
        this.validandoIndice = true;

        let indice = `${this.dataFormulario.foja}-${this.dataFormulario.numero}-${this.dataFormulario.anho}`;

        //let id_cert = this.certificado.id_tipo_certificado

        tituloEntity.getIndiceTitulo(indice).then(res => {
          console.log("respuesta backend en estudio título: ", res.obj);
          if (res.obj == null) {
            this.verModalTituloNoEncontrado = true;
            return;
          }

          this.validandoIndice = false;
          this.fojaCorrecta = res.obj.foja
          this.serie = res.obj;
          if (res.obj.indice_correcto) {
            this.corregirFoja();
          } else {
            this.dialogSerie = true
          }

        });
      } else {
        this.corregirFoja();
      }
    },
    recargarModalSerie() {
      if (this.dialogSerie) {
        this.validarIndice();
      }
    },

    /**
     * Función que le indica al componente padre los datos correctos de la foja, que fue validado previamente a través
     * de una consulta a SCBR
     */
    corregirFoja() {
      this.dialogSerie = false;

      if (this.serie == null) {
        this.serie = {
          foja: this.dataFormulario.foja,
          fojaBuscada: this.dataFormulario.foja,
          numero_carillas: 0,
          precio_carillas: 0,
          nombre_comunidad: "",
        }
      }

      console.log("se enviara la siguiente serie: ", this.serie)
      this.$emit("corregirFoja", this.serie)
    }
  }
}
</script>

<style scoped>

</style>
