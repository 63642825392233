import {getCertificadoFromApi} from "@/model/registros/registros.repository";
import {ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";


const evaluarDocumento = (docbase, evaluado) => {

    if (docbase.id_tipo_registro_FK === ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION) {

        if (evaluado.id_tipo_certificado !== ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES) {
            let isServiu = docbase.tipo_certificado.indexOf("SERVIU") !== -1;
            if (isServiu) {
                return evaluado.tipo_certificado.indexOf("SERVIU") !== -1;
            } else {
                return evaluado.tipo_certificado.indexOf("SERVIU") === -1;
            }
        } else {
            return true;
        }
    }
    return true;
}

const getCertificadoTramitesEnLinea = (url_tipo_registro, url_tipo_certificado) => {
    return new Promise((resolve, reject) => {
        getCertificadoFromApi(url_tipo_registro, url_tipo_certificado).then((response) => {
            if (response.data.status) {

                let array_all_documentos = [];

                let data = response.data.obj;
                data.certificado.numero_copias = 1;
                data.certificado.vigencia = -1;
                array_all_documentos.push(data.certificado);
                data.sugerencias_certificado.map(s => {
                    s.numero_copias = 0;
                    if (evaluarDocumento(data.certificado, s)) {
                        s.vigencia = -1;
                        array_all_documentos.push(s)
                    }

                })

                let obj = {
                    certificado: data.certificado,
                    lista_certificados: array_all_documentos
                }

                resolve(obj);
            } else {
                reject({response: response, mensaje: `No se pudo cargar el certificado`});
            }
        }).catch(function (error) {
            reject(error);
        });
    })
}

export default getCertificadoTramitesEnLinea;
