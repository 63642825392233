import {obtenerCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";


/**
 * Funcion que obtiene el carro para evaluar si el item que se quiere agregar existe o no en el carro actual
 * @param item Representa el certificado que se quiere agregar
 * @returns {boolean} Retorna false si el item no se encuentra en el carro o si no es solo valija electrónica o
 *                    true si es solo valija electrónica o si ya existe en el carro
 */
const isInCarro = (item) => {

    let carroActual = obtenerCarroActual();

    if (item.id_tipo_registro_FK === ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION) {
        console.log("valida item aca: ", item)
        let busqueda = carroActual.filter(f => {
            return f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK;
        })
        console.log("que encontro en busqueda: ", busqueda)
        if (busqueda.length > 0) {
            if (item.solo_valija_electronica) {
                return true;
            } else {
                if (item.solo_anexo) {
                    return true
                }
            }
        }

        return false;
    } else {
        let busqueda = carroActual.filter(f => {
            return f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK && f.indice === item.indice;
        })

        if (busqueda.length > 0) {
            return item.solo_valija_electronica;
        }
    }


    return false;
}

export default isInCarro;
