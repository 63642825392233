import {getSerieFromApi, validarIndiceApi} from "@/model/registros/registros.repository";

export const validarIndice = (numero, anho, id_tipo_indice) =>{
    return validarIndiceApi(numero, anho, id_tipo_indice);
}

const isSerie = (numero, anho, id_tipo_registro, id_tipo_certificado) => {
    return new Promise((resolve, reject) => {
        getSerieFromApi(numero, anho, id_tipo_registro, id_tipo_certificado).then(response => {
            if (response.data.status) {
                resolve(response.data.obj);
            } else {
                reject({
                    response: response, mensaje: "No se pudo validar la serie"
                })
            }
        }).catch(error => {
            reject(error)
        })
    })
}

export default isSerie
