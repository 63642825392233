<template>
  <div>
    <div v-if="listaTitulos.length == 0" class="pt-3">
      <v-alert class="text-center" color="info" dense text outlined>
        No se ha encontrado ningún título
      </v-alert>
    </div>
    <div v-if="listaTitulos.length > 0" class="">
      <div class="pt-2 pb-6 pr-extraSmallChris">

        <v-text-field
            class="pr-extraSmallChris"
            v-model="search"
            append-icon="mdi-magnify"
            label="Filtrar resultados"
            hide-details
        ></v-text-field>

      </div>
      <v-data-table
          dense
          :headers="headers"
          :items="listaTitulosFiltrados"

      >
        <template class="" v-slot:item="{item}">
          <tr>
            <td class="text-start px-0">
              <div class="py-1 px-0" style="font-size: 12px">
                <div class="font-weight-medium px-0">{{ item.nombre_completo }}</div>
                <div class="py-1 px-0 d-flex align-center" style="font-size: 12px">
                  <div>
                    <v-icon color="primary" left small>mdi-map-marker</v-icon>
                  </div>
                  <div>
                    {{ item.ubicacion }}
                    <span class="info--text pointer" v-if="getRutUsuario == null" @click="verMas()">Ver más </span>
                  </div>

                </div>
              </div>
            </td>
            <td class="text-start">
              <div class="py-1 px-0" style="font-size: 12px">
                {{ item.indice }}
              </div>
            </td>
            <td>

              <v-btn color="info" icon class="py-4" @click="verDocumento(item)" v-if="item.documento != ''">
                <v-icon size="19">mdi-magnify</v-icon>
              </v-btn>
            </td>
            <td class="text-start mx-0 px-0">
              <v-btn color="info" icon @click="seleccionarItem(item)" :disabled="cargandoDocumentos"
                     :loading="item.cargando">
                <v-icon size="19">mdi-cart</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>


</template>
<style scoped>
@media (max-width: 600px) {
  .pr-extraSmallChris {
    padding-right: 2px;
    padding-left: 2px;
  }
}

</style>

<script>
import tituloEntity from "@/model/titulo/titulo.entity";
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  name: "PanelTitularesEncontrados",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    listaTitulos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ver: true,
      search: '',
      cargandoDocumentos: false,
      itemSeleccionado: null, //usado para la recarga
      headers: [
        // {
        //   text: 'Dessert (100g serving)',
        //   align: 'start',
        //   filterable: false,
        //   value: 'name',
        // },
        {text: 'Titular', value: 'nombre_completo',align: 'start'},
        {text: 'Índice', value: 'indice', width: "140px",align: 'start'},
        // {text: 'Ubicación', value: 'ubicacion', align: 'justify'},
        { filterable: false, sorterable: false,align: 'start'},
        { filterable: false, sorterable: false,align: 'start'},

      ],
    }
  },
  watch: {
    getRutUsuario() {
      console.log("ejecuta recarga")
      this.$emit("recargarBusqueda")
    }
  },
  computed: {
    getRutUsuario() {
      return usuarioEntity.getRutUsuario()
    },
    listaTitulosFiltrados() {
      let busqueda = this.search.toLowerCase()
      if (this.search == null || this.search.trim() == "") {
        return this.listaTitulos
      } else {
        return this.listaTitulos.filter(f => {
          return f.nombre_completo.toLowerCase().indexOf(busqueda) != -1 || f.indice.toLowerCase().indexOf(busqueda) != -1 || f.ubicacion.toLowerCase().indexOf(busqueda) != -1
        })
      }
    }

  },
  methods: {
    verMas() {
      EventBus.$emit("modal-login", true);
    },

    /**
     * Al seleccionar un elemento de la lista, se irá al backend a consultar cuales son los documentos que se pueden agregar según el título
     * para luego entregarselos al componente padre que los pueda desplegar en el modal de agregar items al carro
     * @param item
     */
    seleccionarItem(item) {
      this.itemSeleccionado = item;
      this.$set(item, "cargado", true);
      this.cargandoDocumentos = true;
      tituloEntity.getDocumentosTentativosByTitulo(item.indice).then(res => {
        console.log("documentos encontrados: ", res)
        item.cargado = false;
        this.cargandoDocumentos = false;

        let indice_split = item.indice.split("-")
        this.$set(item, "foja", indice_split[0])
        this.$set(item, "numero", indice_split[1])
        this.$set(item, "anho", indice_split[2])
        res.obj.map(r => {
          this.$set(r, "numero_copias", 0)
          this.$set(r, "vigencia", -1)
          this.$set(r, "has_litigio", false)
        })
        this.$emit("seleccionarItem", item, res.obj)
      })
    },

    verDocumento(item) {
      usuarioEntity.isSessionActive().then(res => {

        if (res) {
          window.open(`/visor-documentos/${item.indice}/${item.documento}/d`, '_blank');
        } else {
          EventBus.$emit("modal-login", true);
        }
      })

    },
  }
}
</script>
