import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import agregarAlCarro from "@/model/carro/usecases/agregar-al-carro";

const agregarAlCarroInscripciones = (items, datos_adicionales) => {
    items.map(itm => {
        itm.id_tipo_certificado_FK = itm.id_tipo_certificado;
        itm.vigencia = -1;
        itm.indice = itm.indice == null ? "No aplica" : itm.indice;
        itm.precio = itm.precio_pagado;
        if (datos_adicionales) {
            itm.datos_adicionales = [];

            if (datos_adicionales.numero_F2890){
                    itm.datos_adicionales.push({
                        nombre: "formulario_2890",
                        valor: datos_adicionales.numero_F2890,
                        id_tipo_formulario: 9999999,
                        id_area_agrupacion: itm.id_area_agrupacion
                    });
            }

            if(datos_adicionales.notaria_extracto){
                itm.datos_adicionales.push({
                    nombre: "notario_extracto",
                    valor: datos_adicionales.notaria_extracto,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if(datos_adicionales.id_documento_electronico_extracto){
                itm.datos_adicionales.push({
                    nombre: "id_documento_electronico_extracto",
                    valor: datos_adicionales.id_documento_electronico_extracto,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if (datos_adicionales.notaria) {
                itm.datos_adicionales.push({
                    nombre: "notario",
                    valor: datos_adicionales.notaria,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if (datos_adicionales.id_documento_electronico) {
                itm.datos_adicionales.push({
                    nombre: "id_documento_electronico",
                    valor: datos_adicionales.id_documento_electronico,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if (datos_adicionales.indice) {
                itm.datos_adicionales.push({
                    nombre: "Índice",
                    valor: datos_adicionales.indice,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if (datos_adicionales.nombre_sociedad) {
                itm.datos_adicionales.push({
                    nombre: "Nombre de la sociedad",
                    valor: datos_adicionales.nombre_sociedad,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if (datos_adicionales.capital) {
                itm.datos_adicionales.push({
                    nombre: "Capital",
                    valor: datos_adicionales.capital,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });

            }

            if (datos_adicionales.observaciones != null && datos_adicionales.observaciones.trim().length > 0) {
                itm.datos_adicionales.push({
                    nombre: "Observaciones",
                    valor: datos_adicionales.observaciones,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            if(datos_adicionales.link != null && datos_adicionales.link.trim().length > 0){
                itm.datos_adicionales.push({
                    nombre: "Link",
                    valor: datos_adicionales.link,
                    id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
                    id_area_agrupacion: itm.id_area_agrupacion
                });
            }

            console.log("datos adicionales finales del item: " , itm.datos_adicionales)

        }

    })
    let dataFormulario = {
        id_tipo_formulario: ID_TIPO_FORMULARIO.INSCRIPCIONES,
        // datos_adicionales: datos_adicionales
    }

    return agregarAlCarro(items, dataFormulario)
}

export default agregarAlCarroInscripciones;
