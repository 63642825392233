<template>
  <div>
    <div class=" mb-0 pl-2" style="line-height: 19px">
      <div class="d-flex">
        <div class="mr-1">{{ tipo_certificado }}</div>
        <!--<div v-if="indice != null">
          <div class="d-flex">para el título {{ indice }}
            <div class="pl-1" v-if="tipo != null">
              | {{ tipo }}
            </div>
          </div>

        </div>-->
        <strong class="d-flex" v-if="cantidad!=null"> <div class="mr-1">x</div>{{cantidad}}</strong>
      </div>

      <div v-if="info != null">
        <small class="d-flex info--text pl-7">
          {{ info }}
        </small>
      </div>

     <!-- <div v-if="indice != null">
        <small class="d-flex">{{ indice }}
          <div class="pl-1" v-if="tipo != null">
            | {{ tipo }}
          </div>
        </small>
      </div> -->

     <!-- <div v-if="indice == null && cantidad==null">
        <small class="d-flex">
          {{ foja }}
          {{acompanamiento_foja}}
          -
          {{ numero }}
          {{ acompanamiento }}
          -
          {{ anho }}
          <div class="pl-1" v-if="tipo != null">
            | {{ tipo }}
          </div>
        </small>
      </div>-->

    </div>
  </div>
</template>

<script>
export default {
  name: "DescripcionItemScbrEstudioTitulo",
  props: {
    cantidad:null,
    foja: null,
    numero: null,
    anho: null,
    indice: null,
    tipo: null,
    acompanamiento: {default: ""},
    acompanamiento_foja: {default: ""},
    tipo_certificado: null,
    info: null
  }
}
</script>

<style scoped>

</style>
