import usuarioEntity from "@/model/usuario/usuario.entity";

import Vue from 'vue'
import VueRouter from 'vue-router'
import EstudioTituloView from "@/views/index/estudio-titulo/EstudioTituloView";

const IndexView = () => import('@/views/index/index/IndexView.vue');

const TramitesEnLineaView = () => import (/* webpackChunkName: "tramites-chunk" */'@/views/index/tramites-en-linea/TramitesEnLineaView');
const TramitesEnLineaViewMenu = () => import (/* webpackChunkName: "tramites-chunk" */'@/views/index/tramites-en-linea/TramitesEnLineaViewMenu');
const TramitesEnLineaViewMenuRegistro = () => import (/* webpackChunkName: "tramites-chunk" */'@/views/index/tramites-en-linea/TramitesEnLineaViewMenuRegistro');
const TramitesEnLineaViewMenuCertificado = () => import (/* webpackChunkName: "tramites-chunk" */"@/views/index/tramites-en-linea/TramitesEnLineaViewMenuCertificado");

const ConsultasEnLineaView = () => import (/* webpackChunkName: "consultas-chunk" */"@/views/index/consultas-en-linea/ConsultasEnLineaView.vue");
const ConsultasEnLineaViewMenu = () => import (/* webpackChunkName: "consultas-chunk" */"@/views/index/consultas-en-linea/ConsultasEnLineaViewMenu.vue");
const ConsultasEnLineaViewCaratulas = () => import (/* webpackChunkName: "consultas-chunk" */"@/views/index/consultas-en-linea/ConsultasEnLineaViewCaratulas.vue");
const ConsultasEnLineaViewVerificarDocumento = () => import (/* webpackChunkName: "consultas-chunk" */"@/views/index/consultas-en-linea/ConsultasEnLineaViewVerificarDocumento");
const ConsultasEnLineaViewEntregaEnLinea = () => import (/* webpackChunkName: "consultas-chunk" */"@/views/index/consultas-en-linea/ConsultasEnLineaViewEntregaEnLinea");
const ConsultasEnLineaViewEstructurasPublicas = () => import (/* webpackChunkName: "consultas-chunk" */"@/views/index/consultas-en-linea/ConsultasEnLineaViewEscriturasPublicas");
const ConsultasEnLineaViewComercio = () => import(/* webpackChunkName: "consultas-chunk" */"@/views/index/indices/IndicesViewComercio.vue");
const ConsultasEnLineaViewPropiedades = () => import(/* webpackChunkName: "consultas-chunk" */"@/views/index/indices/IndicesViewPropiedades.vue");
const ConsultasEnLineaViewPlanos =() => import(/* webpackChunkName: "consultas-chunk" */"@/views/index/indices/IndicesViewPlanos.vue");

const RegistroView = () => import(/* webpackChunkName: "registro-chunk" */'@/views/index/registro/RegistroView');

const CarroView = () => import (/* webpackChunkName: "carro-chunk" */"@/views/index/carro/CarroView");

/*Cliente chunk*/
const ClienteView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/ClienteView");
const ClienteViewMenu = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/ClienteViewMenu");
const ClienteViewEditar = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/ClienteViewEditar");
const ComprasView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/compras/ComprasView");
const RecuperarCuentaView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/RecuperarCuentaView");
const CambioPasswordRecuperarView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/CambioPasswordRecuperarView");
const MisCaratulasView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/caratulas/MisCaratulasView");
const DevolucionView = () => import(/* webpackChunkName: "cliente-chunk" */"@/views/index/cliente/devolucion/DevolucionView");


/*Informacion Chunk*/
const InformacionView = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionView");
const InformacionViewMenu = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionViewMenu");
const InformacionViewAcercaDe = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionViewAcercaDe");
const InformacionViewTerminosYCondiciones = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionViewTerminosYCondiciones");
const InformacionViewTrabajaConNosotros = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionViewTrabajaConNosotros");
const InformacionViewContacto = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionViewContacto");
const InformacionViewPreguntasFrecuentes = () => import(/* webpackChunkName: "info-chunk" */"@/views/index/informacion/InformacionViewPreguntasFrecuentes");

/*Indices Chunk*/
const IndicesView = () => import (/* webpackChunkName: "indices-chunk" */"@/views/index/indices/IndicesView");
const IndicesVisorPDF = () => import (/* webpackChunkName: "indices-chunk" */"@/views/index/indices/IndicesVisorPDF");

const VidTutorialesView = () => import (/* webpackChunkName: "vid-tutoriales-chunk" */"@/views/index/videotutoriales/VidTutorialesView");
const CaratulasPorCorregirView = () => import (/* webpackChunkName: "car-por-corregir-chunk" */"@/views/index/cliente/caratulas-por-corregir/CaratulasPorCorregirView");
const InscripcionesViewMenu = () => import (/* webpackChunkName: "inscripciones-chunk" */"@/views/index/inscripciones/InscripcionesViewMenu");
const InscripcionesView = () => import (/* webpackChunkName: "inscripciones-chunk" */"@/views/index/inscripciones/InscripcionesView");

const AgregarInscripcionesView = () => import (/* webpackChunkName: "agregar-chunk" */"@/views/index/inscripciones/propiedad/inscripcion-propiedad/AgregarInscripcionesView");
const AgregarConstitucionSociedadView = () => import (/* webpackChunkName: "agregar-chunk" */"@/views/index/inscripciones/comercio/constitucion-sociedad/AgregarConstitucionSociedadView");
const AgregarModificacionSociedadView = () => import (/* webpackChunkName: "agregar-chunk" */"@/views/index/inscripciones/comercio/modificacion-sociedad/AgregarModificacionSociedadView");
const AgregarAlzPrhServiuPv = () => import (/* webpackChunkName: "agregar-chunk" */"@/views/index/inscripciones/propiedad/alzamiento-prh-serviu-plazo-vencido/AgregarAlzPrhServiuPv");
const AgregarConDiarioOficialView = () => import (/* webpackChunkName: "agregar-chunk" */"@/views/index/inscripciones/comercio/con-diario-oficial/AgregarConDiarioOficialView");

const PaginaNoEncontradaView = () => import(/* webpackChunkName: "404-chunk" */"@/views/index/pagina-no-encontrada/PaginaNoEncontradaView");

const BoletasView = () => import(/* webpackChunkName: "boletas-chunk" */"@/views/index/boletas/BoletasView");
const ActivacionView = () => import(/* webpackChunkName: "activacion-chunk" */"@/views/index/activacion/ActivacionView");
const VerificacionPagoView = () => import(/* webpackChunkName: "verificar-pago-chunk" */"@/views/index/verificacion-pago/VerificacionPagoView");

const PagoMovilView = () => import(/* webpackChunkName: "pago-movil-chunk" */"@/views/index/pago-movil/PagoMovilView");

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: IndexView
    },
    {
        path: '/consultas',
        component: ConsultasEnLineaView,
        name: "Consulta",
        children: [
            {
                path: '/',
                name: 'ConsultasMenu',
                component: ConsultasEnLineaViewMenu,
            },
            {
                path: 'caratulas/:ncaratula(\\d+)?',
                name: 'ConsultasCaratulas',
                component: ConsultasEnLineaViewCaratulas,
            },
            {
                path: 'entrega-en-linea/:ncaratula(\\d+)?',
                name: 'EntregaEnLinea',
                component: ConsultasEnLineaViewEntregaEnLinea,
            },
            {
                path: 'verificar-documento/:ndocumento(\\d+)?',
                name: 'ConsultasVerificarDocumento',
                component: ConsultasEnLineaViewVerificarDocumento,
            },
            {
                path: "escrituras-publicas",
                name: "EscriturasPublicas",
                component: ConsultasEnLineaViewEstructurasPublicas,
            },
            {
                path: "indices-comercio",
                name: "IndicesComercio",
                component: ConsultasEnLineaViewComercio,
            },
            {
                path: "indices-propiedad-aguas",
                name: "IndicesPropiedades",
                component: ConsultasEnLineaViewPropiedades,
            },
            {
                path: "planos",
                name: "Planos",
                component: ConsultasEnLineaViewPlanos,
            }
        ]
    },
    {
        path: '/tramites',
        component: TramitesEnLineaView,
        name: "Tramites",
        children: [
            {
                path: '/',
                name: 'TramitesMenu',
                component: TramitesEnLineaViewMenu,
            },
            {
                path: "propiedad/estudio-titulo",
                name: "EstudioTitulo",
                component: EstudioTituloView
            },
            {
                path: ':categoriaRegistro',
                name: 'CategoriasRegistros',
                component: TramitesEnLineaViewMenuRegistro,

            },
            {
                path: ":categoriaRegistro/:tipoRegistro/:tipoCertificado",
                name: "TipoCertificado",
                component: TramitesEnLineaViewMenuCertificado
            }
        ]
    },
    {
        path: '/inscripciones',
        name: "Inscripciones",
        component: InscripcionesView,
        children: [
            {
                path: '/',
                name: 'InscripcionesMenu',
                component: InscripcionesViewMenu,
            },
            {
                path: 'inscripcion-propiedad',
                name: 'InscripcionPropiedad',
                component: AgregarInscripcionesView,
            },
            {
                path: 'constitucion-de-sociedad',
                name: 'ConstitucionDeSociedad',
                component: AgregarConstitucionSociedadView
            },
            {
                path: 'modificacion-de-sociedad',
                name: 'ModificacionDeSociedad',
                component: AgregarModificacionSociedadView
            },
            {
                path: 'alzamiento-prohibicion-serviu',
                name: 'AlzamientoProhibicionServiu',
                component: AgregarAlzPrhServiuPv
            },
            {
                path: "comercio-diario-oficial",
                name: "InscripcionComercioDiarioOficial",
                component: AgregarConDiarioOficialView
            }
        ]
    },
    {
        path: '/registro',
        name: 'Registro',
        component: RegistroView
    },
    {
        path: '/informacion',
        component: InformacionView,
        name: "Informacion",
        children: [
            {
                path: '/',
                name: 'InformacionMenu',
                component: InformacionViewMenu,
            },
            {
                path: 'acerca-de',
                name: 'InformacionAcercaDe',
                component: InformacionViewAcercaDe,
            },
            {
                path: 'tyc',
                name: 'InformacionTerminosYCondiciones',
                component: InformacionViewTerminosYCondiciones,
            },
            {
                path: 'trabaja-con-nosotros',
                name: 'InformacionTrabajaConNosotros',
                component: InformacionViewTrabajaConNosotros,
            },
            {
                path: 'contacto',
                name: 'InformacionContacto',
                component: InformacionViewContacto,
            },
            {
                path: 'faq',
                name: 'InformacionPreguntasFrecuentes',
                component: InformacionViewPreguntasFrecuentes,
            }
        ]
    },
    {
        path: '/carro',
        component: CarroView,
        name: "Carro",
    },
    {
        path: "/cliente",
        component: ClienteView,
        name: "Cliente",
        beforeEnter: (to, from, next) => {

            usuarioEntity.isSessionActive().then((active) => {
                if (active) {
                    next();
                } else {
                    next({path: '/'});
                }
            });

        },
        children: [
            {
                path: "/",
                component: ClienteViewMenu,
                name: "ClienteMenu",
                beforeEnter: (to, from, next) => {
                    comprobarLogin(to, from, next);
                },
            },
            {
                path: "editar",
                component: ClienteViewEditar,
                name: "ClienteEditar",
                beforeEnter: (to, from, next) => {
                    comprobarLogin(to, from, next);
                },
            },
            {
                path: "compras",
                component: ComprasView,
                name: "Compras",
                beforeEnter: (to, from, next) => {
                    comprobarLogin(to, from, next);
                },
            },
            {
                path: "caratulas",
                component: MisCaratulasView,
                name: "Caratulas",
                children: [
                    {
                        path: ":numero_caratula",
                        component: MisCaratulasView
                    }
                ],

            },
            {
                path: "caratulas-por-corregir",
                component: CaratulasPorCorregirView,
                name: "CaratulasPorCorregir"
            }

        ]
    },
    {
        path: "/devoluciones",
        component: DevolucionView,
        name: "Devoluciones",
        children: [
            {
                path: ":numero_caratula",
                component: DevolucionView
            }
        ]
    },
    {
        path: "/recuperar-cuenta",
        component: RecuperarCuentaView,
        name: "RecuperarCuenta",
    },
    {
        path: "/recuperacion",
        component: CambioPasswordRecuperarView,
        name: "CambioPasswordRecuperar",
    },
    {
        path: "/verificacion-pago",
        component: VerificacionPagoView,
        name: "VerificacionaPago",
        children: [
            {
                path: ":seccion",
                component: VerificacionPagoView,
                children: [
                    {
                        path: ":recaudador",
                        component: VerificacionPagoView,
                        children: [{
                            path: ":id",
                            component: VerificacionPagoView,
                        }

                        ]
                    }
                ]
            }
        ]
    },
    {
        path: "/indices",
        component: IndicesView,
        name: "Indices",
        children: [

            {
                path: ":tipo_registro",
                component: IndicesView
            }

        ]
    },
    {
        path: "/visor-documentos/:serie/:idDocumento/:tipo",
        component: IndicesVisorPDF,
    },
    {
        path: "/videotutoriales",
        component: VidTutorialesView,
        name: "Videotutoriales"
    },
    {
        path: '/boleta-no-disponible',
        component: BoletasView,
        name: "Boletas"
    },
    {
        path: '/activacion/:rut_cliente/:codigo',
        component: ActivacionView,
        name: "Activacion"
    },
    {
        path: '/pagomovil',
        name: 'PagoMovil',
        component: PagoMovilView
    },
    {
        path: "*",
        component: PaginaNoEncontradaView
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

const comprobarLogin = (to, from, next) => {
    usuarioEntity.isSessionActive().then((active) => {
        if (active) {
            next();
        } else {
            next({path: '/'});
        }
    });
}

export default router
