import {obtenerCarroActual} from "@/model/carro/carro.repository";


const getResumen = () => {

    let carro = obtenerCarroActual();

    let resumen = {
        precio_total: 0
    };

    carro.map(item => {
        resumen.precio_total += item.precio * item.numero_copias;

    })

    resumen.cantidad_documentos = carro.length.toString();

    return resumen;


}

export default getResumen;