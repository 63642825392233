<template>
  <div>
    <v-navigation-drawer
        style="height: 100%"
        :value="value"
        @input="$emit('input', $event)"
        clipped
        fixed
        disable-resize-watcher
        app
    >
      <v-list v-if="nombreUsuario != null">
        <v-list-item link>
          <v-list-item-icon class="mr-2">
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="title">{{ nombreUsuario }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="ma-2" v-else>
        <v-btn
            outlined
            block
            text
            color="primary"
            @click="handleIniciarSesion()"
        >
          <v-icon class="mr-1" small>mdi-login</v-icon>
          Ingresar
        </v-btn>
      </div>

      <v-divider v-if="nombreUsuario != null"></v-divider>

      <v-list nav v-if="nombreUsuario != null">
        <v-list-item to="/cliente" exact>
          <v-list-item-title>Mi cuenta</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>

        <v-list-item to="/" exact>
          <v-list-item-title>Inicio</v-list-item-title>
        </v-list-item>


        <v-list-group
            :value="false"
        >
          <template v-slot:activator>
            <v-list-item-title>Trámites</v-list-item-title>
          </template>

          <v-list-group
              v-for="(tramite, t) in tramitesList"
              :value="false"
              :key="t"
              sub-group
              :prepend-icon="tramite.icono"
              class="no-rotate"
          >
            <template
                v-slot:activator
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ tramite.tipo_registro }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item
                style="background-color: white; "
                v-for="(certificado, i) in tramite.certificados"
                :key="i"
                class="ml-0"

                :to="certificado.link"

            >
              <v-list-item-title v-text="certificado.tipo_certificado"></v-list-item-title>

            </v-list-item>
          </v-list-group>

        </v-list-group>

        <v-list-item to="/consultas">
          <v-list-item-title>Consultas en línea</v-list-item-title>
        </v-list-item>

        <v-list-item to="/consultas/entrega-en-linea">
          <v-list-item-title>Entrega en línea</v-list-item-title>
        </v-list-item>

        <v-list-item to="/informacion">
          <v-list-item-title>Información CBRLS</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div v-if="nombreUsuario != null" class="pa-2">
          <v-btn block outlined color="primary" @click="handleCerrarSesion()">
            <v-icon class="mr-1" small>mdi-lock</v-icon>
            Cerrar sesión
          </v-btn>
        </div>
      </template>

    </v-navigation-drawer>
  </div>
</template>

<script>
import usuario from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  name: "IndexCompoundDrawer",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tramitesList: [
        {
          tipo_registro: "Registro de Propiedad",
          icono: "mdi-home",
          certificados: [
            {
              tipo_certificado: "Estudio de Título 10 años",
              link: "/tramites/propiedad/estudio-titulo"
            },
            {
              tipo_certificado: "Copia con Vigencia o Dominio Vigente",
              link: "/tramites/propiedad/copias-y-certificados-propiedad/copia-con-vigencia-o-dominio-vigente"
            },
            {
              tipo_certificado: "Cert. de Hip y Gravámenes",
              link: "/tramites/propiedad/copias-y-certificados-propiedad/cert-hipotecas-gravamenes"
            },

            {
              tipo_certificado: "Inscripción de Propiedad", link: "/inscripciones/inscripcion-propiedad"
            },
            {
              tipo_certificado: "Otros trámites", link: "/tramites/propiedad"
            }
          ]
        },
        {
          tipo_registro: "Registro de Comercio",
          icono: "mdi-store",
          certificados: [
            {
              tipo_certificado: "Copia con Vigencia",
              link: "/tramites/comercio/copias-y-certificados-comercio/copia-con-vigencia"
            },
            {
              tipo_certificado: "Cert. de Vigencia de Sociedad",
              link: "/tramites/comercio/copias-y-certificados-comercio/cert-vigencia-sociedad"
            },
            {
              tipo_certificado: "Constitución de Sociedad", link: "/inscripciones/constitucion-de-sociedad"
            },
            {
              tipo_certificado: "Modificación de Sociedad", link: "/inscripciones/modificacion-de-sociedad"
            },
            {
              tipo_certificado: "Otros trámites", link: "/tramites/comercio"
            }
          ]
        },
        {
          tipo_registro: "Registro de Aguas",
          icono: "mdi-water-pump",
          certificados: [
            {
              tipo_certificado: "Ver trámites", link: "/tramites/aguas"
            }
          ]
        },
        {
          tipo_registro: "Registro de Minas",
          icono: "mdi-pickaxe",
          certificados: [
            {
              tipo_certificado: "Ver trámites", link: "/tramites/minas"
            }
          ]
        },
        {
          tipo_registro: "Escrituras públicas",
          icono: "mdi-file",
          certificados: [
            {
              tipo_certificado: "Ver trámites", link: "/tramites/escrituras-publicas"
            }
          ]
        }
      ]
    }
  },
  computed: {
    nombreUsuario: function () {
      return usuario.getNombreUsuario();
    },
  },
  methods: {
    handleIniciarSesion() {
      EventBus.$emit("modal-login", true);
    },
    handleCerrarSesion() {
      usuario.cerrarSesionUsuario().then(() => {
        EventBus.$emit("dialog-sesion-finalizada", true);
        EventBus.$emit("login", false);
      }).catch((error) => {
        console.error(error);
      })
    }
  }
};
</script>


<style scoped>
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
  opacity: 0;
  color: black;
}

.v-list-item__title, .v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black;
  caret-color: black !important;
}

.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
  opacity: 0;
}

.no-rotate.v-list-group--active::v-deep .v-list-item__icon.v-list-group__header__prepend-icon .v-icon {
  transform: rotate(0deg);
}
</style>