import {ID_TIPO_CERTIFICADO} from "@/model/registros/registros.entity";
import getFromTiposRegistrosTiposCertificados
    from "@/model/registros/usecases/get-certificados/get-from-tipos-registros-tipos-certificados";

const getCertificadoLitigios = (id_tipo_registro) => {

    return getFromTiposRegistrosTiposCertificados(id_tipo_registro, ID_TIPO_CERTIFICADO.LITIGIOS)
}


export default getCertificadoLitigios;
