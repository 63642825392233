<template>
  <IndexCompound/>
</template>


<script>
import IndexCompound from "@/views/IndexCompound.vue";
import usuarioEntity from "@/model/usuario/usuario.entity";

export default {
  name: "App",
  components: {
    IndexCompound,
  },
  created() {
    //comprobar si la sesión está activa
    usuarioEntity.isSessionActive();
  }
};
</script>

<style>

.input-spin {
  width: 40px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  outline: 0 none;
  font-size: 12px;
  border-top: 1px solid #19aec5;
  border-bottom: 1px solid #19aec5;
  height: 26px;

}
.vue-friendly-iframe iframe {
     border: 0; width: 100%; height: 79vh; min-width: 200px;
}
.font-md {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
}

.pointer{
  cursor: pointer;
}
</style>
<style lang="sass">

.table-bordered,
.table-bordered td,
.table-bordered th
  border: 1px solid #dee2e6

.table
  width: 100%
  max-width: 100%
  margin-bottom: 1rem
  background-color: transparent
  border-collapse: collapse

  & thead
    background-color: #d1ecf1

.card-minimal
  max-width: 600px

</style>
