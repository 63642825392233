import getAllComunasDeRegiones from "@/model/usuario/usecases/get-all-comunas-de-regiones";
import cerrarSesion from "@/model/usuario/usecases/cerrar-sesion";
import isSessionActive from "@/model/usuario/usecases/is-session-active";
import readUsuario from "@/model/usuario/usecases/read-usuario";
import getCorreoUsuario from "@/model/usuario/usecases/get-correo-usuario";
import getIdComunaUsuario from "@/model/usuario/usecases/get-comuna-usuario";
import updateUsuario from "@/model/usuario/usecases/update-usuario";
import cambiarPassLogeado from "@/model/usuario/usecases/cambiar-pass-logeado";
import getMisCompras from "@/model/usuario/usecases/get-mis-compras";
import recuperarCuenta from "@/model/usuario/usecases/recuperar-cuenta";
import verificarUpdatePasswordRecuperacionCuenta from "@/model/usuario/usecases/verificar-update-password-recuperacion-cuenta";
import updatePasswordRecuperarCuenta from "@/model/usuario/usecases/update-password-recuperar-cuenta";
import getNombreUsuario from "@/model/usuario/usecases/get-nombre-usuario";
import getRutUsuario from "@/model/usuario/usecases/get-rut-usuario";
import {iniciarSesionUsuario} from "@/model/usuario/usecases/iniciar-sesion";
import registrarUsuario from "@/model/usuario/usecases/create-usuario";
import getDireccionUsuario from "@/model/usuario/usecases/get-direccion-usuario";
import updateActivacionCliente from "@/model/usuario/usecases/update-activacion-cliente";
import getEstadoActivacionCliente from "@/model/usuario/usecases/get-estado-activacion-cliente";
import updateVerificacionClienteSesion from "@/model/usuario/usecases/update-verificacion-cliente-sesion";
import verificarVersionTerminosCondiciones from "@/model/usuario/usecases/verificar-version-terminos-condiciones";
import updateTerminosYCondicionesUsuario from "@/model/usuario/usecases/update-terminos-y-condiciones-usuario";
import getNombreUsuarioByRut from "@/model/usuario/usecases/get-nombre-usuario-by-rut";
import getEstadoVisualizacionInformacionInicio
    , {setEstadoVisualizacionInformacionInicio} from "@/model/usuario/usecases/get-estado-visualizacion-informacion-inicio";

export const RUT_USUARIO = {
    WEB: -3
}

const usuarioEntity = {
    getNombreUsuario: getNombreUsuario,
    getRutUsuario: getRutUsuario,
    getDireccionUsuario: getDireccionUsuario,
    getCorreoUsuario: getCorreoUsuario,
    getIdComunaUsuario: getIdComunaUsuario,
    iniciarSesionUsuario: iniciarSesionUsuario,
    isSessionActive: isSessionActive,
    cerrarSesionUsuario: cerrarSesion,
    registrarUsuario: registrarUsuario,
    getAllComunasDeRegiones: getAllComunasDeRegiones,
    updateUsuario: updateUsuario,
    cambiarPassLogeado: cambiarPassLogeado,
    readUsuario: readUsuario,
    getMisCompras: getMisCompras,
    recuperarCuenta: recuperarCuenta,
    verificarUpdatePasswordRecuperarcuenta: verificarUpdatePasswordRecuperacionCuenta,
    updatePasswordRecuperarCuenta: updatePasswordRecuperarCuenta,
    updateActivacionCliente,
    getEstadoActivacionCliente,
    updateVerificacionClienteSesion,
    verificarVersionTerminosCondiciones,
    updateTerminosYCondicionesUsuario,
    getNombreUsuarioByRut,
    getEstadoVisualizacionInformacionInicio,
    setEstadoVisualizacionInformacionInicio

};

export default usuarioEntity;
