var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","dense":"","color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],on:{"click":function($event){$event.stopPropagation();return _vm.showDrawer()}}}),_c('div',{staticClass:"d-flex align-center"},[_c('router-link',{attrs:{"replace":"","to":"/"}},[_c('v-img',{staticClass:"mr-2",attrs:{"alt":"CBRLS Logo","contain":"","src":require('@/assets/img/cbrls-logo-white.svg'),"width":"130","eager":""}})],1)],1),_c('v-tabs',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.smAndDown),expression:"!$vuetify.breakpoint.smAndDown"}],attrs:{"centered":"","optional":"","hide-slider":""}},[_c('v-menu',{attrs:{"offset-y":true,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_vm._v(" Trámites en línea "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"pb-0 pt-0",attrs:{"dense":"","flat":""}},_vm._l((_vm.menuTramitesEnLinea),function(registro){return _c('div',{key:registro.nombre},[_c('v-list-item',{staticClass:"grey lighten-3 ",attrs:{"to":registro.link ? registro.link : null}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[_c('v-icon',{staticClass:"iconColor",attrs:{"left":"","size":"20"}},[_vm._v(" "+_vm._s(registro.icono)+" ")]),_vm._v(" "+_vm._s(registro.nombre)+" ")],1)],1),_c('v-list-item-action',{staticClass:"my-0"},[(registro.nuevo)?_c('v-chip',{staticClass:"py-0 white--text",attrs:{"x-small":"","color":"orange lighten-1"}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1),_vm._l((registro.hijos),function(certificado){return _c('v-list-item',{key:certificado.link,staticClass:"py-0",attrs:{"exact":"","to":certificado.link}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-subtitle',[_vm._v(_vm._s(certificado.nombre))])],1),_c('v-list-item-action',{staticClass:"my-0"},[(certificado.nuevo)?_c('v-chip',{staticClass:"py-0 white--text",attrs:{"x-small":"","color":"orange lighten-1"}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1)})],2)}),0)],1),_c('v-menu',{attrs:{"offset-y":true,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_vm._v(" Consultas en línea "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"pb-0",attrs:{"dense":"","flat":""}},_vm._l((_vm.menuConsultaEnLinea),function(item){return _c('div',{key:item.nombre},[_c('v-list-item',{attrs:{"link":"","to":item.link}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"iconColor",attrs:{"left":"","size":"20"}},[_vm._v(" "+_vm._s(item.icono)+" ")]),_vm._v(" "+_vm._s(item.nombre)+" ")],1),_c('v-list-item-action',{staticClass:"my-0"},[(item.nuevo)?_c('v-chip',{staticClass:"py-0 white--text",staticStyle:{"width":"70px"},attrs:{"x-small":"","color":"orange lighten-1"}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1)],1)}),0)],1),_c('v-tab',{attrs:{"replace":"","to":"/consultas/entrega-en-linea"}},[_vm._v("Entrega en línea")]),_c('v-menu',{attrs:{"offset-y":true,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({},'v-tab',attrs,false),on),[_vm._v(" Información "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',{staticClass:"pb-0",attrs:{"dense":"","flat":""}},_vm._l((_vm.menuInformacion),function(item){return _c('div',{key:item.nombre},[_c('v-list-item',{attrs:{"link":"","to":item.link}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"iconColor",attrs:{"left":"","size":"20"}},[_vm._v(" "+_vm._s(item.icono)+" ")]),_vm._v(" "+_vm._s(item.nombre)+" ")],1)],1)],1)}),0)],1)],1),_c('v-spacer'),_c('v-badge',{attrs:{"content":_vm.getCantidadDocumentosCarro,"color":"red","overlap":""}},[_c('v-btn',{staticClass:"black--text",attrs:{"small":"","color":"white","outlined":""},on:{"click":function($event){return _vm.irAlCarro()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-cart")]),_vm._v(" "+_vm._s(_vm._f("clp")(_vm.getPrecioTotalCarro))+" ")],1)],1),(_vm.nombreUsuario == null)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],staticClass:"ml-3 black--text",attrs:{"small":"","color":"white","outlined":""},on:{"click":function($event){return _vm.handleIniciarSesion()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-login")]),_vm._v(" Ingresar ")],1):_c('v-menu',{attrs:{"offset-y":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}],staticClass:"ml-3 black--text",attrs:{"small":"","color":"white","outlined":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-account")]),_vm._v(" "+_vm._s(_vm.nombreUsuario)+" ")],1)]}}])},[_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item',{attrs:{"to":"/cliente"}},[_c('v-list-item-title',[_vm._v("Mi cuenta")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.handleCerrarSesion()}}},[_c('v-list-item-title',[_vm._v("Cerrar sesión")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }