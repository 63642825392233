// eslint-disable-next-line no-unused-vars
import {agregarAlCarroApi, obtenerCarroActual, updateCarroActual} from "@/model/carro/carro.repository";
import {ID_TIPO_FORMULARIO} from "@/model/formularios/formulario.entity";
import getCarro from "@/model/carro/usecases/get-carro";
import usuarioEntity from "@/model/usuario/usuario.entity";
import isInCarro from "@/model/carro/usecases/verificar-restricciones";
import isLimiteExedido from "@/model/carro/usecases/vertificar-cantidad-elementos-grupo-carro";
import {ID_AREA_AGRUPACION} from "@/model/registros/registros.entity";
import {ID_TIPO_TRAMITE} from "@/model/tramites/tramites.entity";


/**
 *
 * @param items Es una lista de certificados que se van a agregar al carro
 * @param dataFormulario Es un objeto que contiene diversos datos:
 *      dataFormulario: {
 *          id_tipo_formulario:
 *          datos_adicionales: {
 *              nombre: "nombre del dato adicional",
 *               valor: "valor del dato adicional",
 *               id_tipo_formulario: Ej: ID_TIPO_FORMULARIO.FNA, :
 *                  "Este dato sirve para determinar de que manera le extraerá los datos a los documentos, este dato viene desde
 *                  la base de datos desde 'tipos_registros_has_tipos_certificados.id_tipo_formulario'"
 *               id_area_agrupacion: Ej: ID_AREA_AGRUPACION.ALZAMIENTO_PROHIBICION_PLAZO_VENCIDO :
 *                  "Se utiliza para determinar a que grupo de documentos van asignados los datos adicionales,
 *                  debe coincidir con el area del certificado indicada en base de datos en la tabla
 *                  'tipos_registros_has_tipos_certificados.id_area_agrupacion_FK'"
 *          }
 *      }
 * @param precio_carillas -
 * @returns {Promise<Object>} Al terminar de agregar al carro, retorna un objeto con 3 arrays:
 *      Promise<Object>: {
 *          documentos_repetidos: "Contiene los documentos que ya se encontraban en el carro y no se puede agregar más de 1 a la vez",
 *          limite_carro: "Contiene los items que no se pudieron agregar debido a que el carro llegó a la cantidad máxima de items permitidos",
 *          lista_agregados: "Contiene todos los items que se agregaron correctamente al carro"
 *      }
 *
 */
const agregarAlCarro = (items, dataFormulario, precio_carillas) => {
    let listaCertificados = JSON.parse(JSON.stringify(items));
    console.log("lista certificados", listaCertificados)
    listaCertificados.map(cer => {
        cer.precio_carillas = precio_carillas;
        cer.precio = parseInt(cer.precio) + (cer.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(precio_carillas) : 0);
        cer.id_tipo_tramite = cer.id_tipo_tramite == null ? ID_TIPO_TRAMITE.NO_DEFINIDO : cer.id_tipo_tramite
    })
    let listaCarro = [];
    let listaRepetidos = [];
    let listaCarroLimite = [];
    return new Promise((resolve, reject) => {

        listaCertificados.map(item => {

            let carroActual = obtenerCarroActual();
            if (item.numero_copias > 0) {

                item.id_tipo_certificado_FK = item.id_tipo_certificado;

                /**
                 * En ciertos lugares del front de portal cbrls y plataforma interna se utiliza el campo vigencia, el cual se
                 * usa generalmente en el certificado de hipotecas y gravámenes, se le asigna el -1 para que no sea
                 * mostrado el valor en front cuando no se necesita
                 */
                if (item.vigencia === 0 || item.vigencia == null) {
                    item.vigencia = -1;
                }
                let alzamiento = null;
                switch (dataFormulario.id_tipo_formulario) {
                    case ID_TIPO_FORMULARIO.FNA:
                        item.indice = item.indice == null ? `${dataFormulario.foja}-${dataFormulario.numero}-${dataFormulario.anho}` : item.indice;
                        if (dataFormulario.datos_adicionales != null) {
                            item.datos_adicionales = dataFormulario.datos_adicionales.filter(d => {
                                return d.valor != null
                            });
                        }
                        break;
                    case ID_TIPO_FORMULARIO.PLANOS:
                        item.indice = `${dataFormulario.foja}-${dataFormulario.numero}-${dataFormulario.anho}`;
                        break;
                    case ID_TIPO_FORMULARIO.ALZAMIENTO_Y_CANCELACION:

                        /**
                         * Los datos de alzamientos se insertan por separado en una tabla llamada 'alzamientos_de_carro_compras'
                         * y se sacan de forma diferente a los datos adicionales
                         */

                        alzamiento = {
                            id_documento_electronico: dataFormulario.idDocumento,
                            repertorio: dataFormulario.repertorio,
                            partes: dataFormulario.partes,
                            notaria: dataFormulario.notario.nombre
                        }

                        item.indice = "No aplica";
                        item.alzamiento = alzamiento;
                        break;
                    case ID_TIPO_FORMULARIO.ESCRITURAS_PUBLICAS:
                        item.indice = "No aplica";
                        console.log("ITEM: ", item);
                        console.log("dataFormulario: ", dataFormulario)
                        item.datos_adicionales = dataFormulario.datos_adicionales.filter(d => {
                            return d.valor != null
                        });
                        break;
                    case ID_TIPO_FORMULARIO.INSCRIPCIONES:

                        break;
                    case ID_TIPO_FORMULARIO.ALZAMIENTO_PLAZO_VENCIDO:
                        if (dataFormulario.datos_adicionales != null) {
                            item.datos_adicionales = dataFormulario.datos_adicionales.filter(d => {
                                return d.valor != null
                            });
                        }
                        break;
                    default:
                        reject({response: null, mensaje: "Formulario no soportado"})
                        return;
                }

                /**
                 * Se evalúa si se pueden agregar mas elementos al carro, en caso de no poderse, se agregan al arreglo
                 * listaCarroLimite
                 */

                if (!isLimiteExedido(item)) {

                    /**
                     * Si es que aún existe espacio para agregar mas elementos al carro, evalúa que el item que se
                     * quiera agregar no exista en el carro, o si existe, que no tenga la limitación de solo poder agregar
                     * 1 elemento
                     */
                    if (isInCarro(item)) {
                        listaRepetidos.push(item)
                    } else {

                        /**
                         * Si está permitido agregar el item, busca alguna coincidencia en la lista de carros para ver si
                         * es necesario aumentar la cantidad del item en cuestion
                         */
                        let busqueda = carroActual.filter(f => {
                            return f.indice === item.indice && f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK && item.precio == f.precio
                        })

                        if (busqueda.length > 0) {
                            carroActual.filter(f => {
                                return f.indice === item.indice && f.id_tipo_registro_FK === item.id_tipo_registro_FK && f.id_tipo_certificado_FK === item.id_tipo_certificado_FK && item.precio == f.precio
                            })[0].numero_copias += item.numero_copias;
                        } else {
                            carroActual.push(item)
                        }

                        listaCarro.push(item);

                        /**
                         * Actualiza el carro sin sesión
                         */
                        updateCarroActual(carroActual);
                    }
                } else {
                    listaCarroLimite.push(item);
                }
            }
        })


        /**
         * Si es que existe sesión, actualizará el carro en base de datos
         * si no está activo, solo retorna el objeto construido con la informacion de documentos agregados y cuales no
         */

        usuarioEntity.isSessionActive().then(resp => {
            if (resp) {
                console.log("estoy Con sesion" , {
                    documentos_repetidos: listaRepetidos,
                    limite_carro: listaCarroLimite,
                    lista_agregados: listaCarro
                })
                agregarAlCarroApi(listaCarro).then(response => {
                    if (response.data.status) {
                        getCarro().then();
                        resolve({
                            documentos_repetidos: listaRepetidos,
                            limite_carro: listaCarroLimite,
                            lista_agregados: listaCarro
                        });
                    } else {
                        reject({response: response, mensaje: "No se pudo agregar al carro"})
                    }
                })
            } else {
                resolve({
                    documentos_repetidos: listaRepetidos,
                    limite_carro: listaCarroLimite,
                    lista_agregados: listaCarro
                });
                console.log("estoy sin sesion", {
                    documentos_repetidos: listaRepetidos,
                    limite_carro: listaCarroLimite,
                    lista_agregados: listaCarro
                })
            }
        })


    })

}

export default agregarAlCarro;
