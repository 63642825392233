<template>
  <div>
    <v-app-bar app dense color="primary" dark>
      <v-app-bar-nav-icon
          v-show="$vuetify.breakpoint.smAndDown"
          @click.stop="showDrawer()"
      >
      </v-app-bar-nav-icon>

      <div class="d-flex align-center">
        <router-link replace to="/">
          <v-img
              alt="CBRLS Logo"
              class="mr-2"
              contain
              :src="require('@/assets/img/cbrls-logo-white.svg')"
              width="130"
              eager
          />
        </router-link>
      </div>

      <!-- app bar pantallas grandes-->
      <v-tabs
          v-show="!$vuetify.breakpoint.smAndDown"
          centered
          optional
          hide-slider
      >
        <v-menu
            :offset-y="true"
            left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tab
                v-bind="attrs"
                v-on="on"
            >
              Trámites en línea
              <v-icon right>mdi-menu-down</v-icon>
            </v-tab>
          </template>

          <v-list dense flat class="pb-0 pt-0">
            <div
                v-for="registro in menuTramitesEnLinea"
                :key="registro.nombre"
            >
              <!-- título -->
              <v-list-item
                  class="grey lighten-3 "
                  :to="registro.link ? registro.link : null"
              >
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <v-icon
                        left
                        size="20"
                        class="iconColor"
                    >
                      {{ registro.icono }}
                    </v-icon>
                    {{ registro.nombre }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-chip
                      v-if="registro.nuevo"
                      x-small
                      class="py-0 white--text"
                      color="orange lighten-1"
                  >
                    Nuevo
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
              <!-- ítems -->
              <v-list-item
                  v-for="certificado in registro.hijos"
                  exact
                  :to="certificado.link"
                  :key="certificado.link"
                  class="py-0"
              >
                <v-list-item-content class="py-0">
                  <v-list-item-subtitle>{{ certificado.nombre }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="my-0">
                  <v-chip
                      v-if="certificado.nuevo"
                      x-small
                      class="py-0 white--text"
                      color="orange lighten-1"
                  >
                    Nuevo
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <v-menu
            :offset-y="true"
            left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tab
                v-bind="attrs"
                v-on="on"
            >
              Consultas en línea
              <v-icon right>mdi-menu-down</v-icon>
            </v-tab>
          </template>

          <v-list dense flat class="pb-0">
            <div
                v-for="item in menuConsultaEnLinea"
                :key="item.nombre"
            >
              <!-- título -->
              <v-list-item link :to="item.link">
                <v-list-item-title>
                  <v-icon
                      left
                      size="20"
                      class="iconColor"
                  >
                    {{ item.icono }}
                  </v-icon>
                  {{ item.nombre }}
                </v-list-item-title>
                <v-list-item-action class="my-0">
                  <v-chip
                      v-if="item.nuevo"
                      x-small
                      class="py-0 white--text"
                      color="orange lighten-1"
                      style="width: 70px"
                  >
                    Nuevo
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>

        <v-tab replace to="/consultas/entrega-en-linea">Entrega en línea</v-tab>

        <v-menu
            :offset-y="true"
            left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-tab
                v-bind="attrs"
                v-on="on"
            >
              Información
              <v-icon right>mdi-menu-down</v-icon>
            </v-tab>
          </template>

          <v-list dense flat class="pb-0">
            <div
                v-for="item in menuInformacion"
                :key="item.nombre"
            >
              <!-- título -->
              <v-list-item link :to="item.link">
                <v-list-item-title>
                  <v-icon
                      left
                      size="20"
                      class="iconColor"
                  >
                    {{ item.icono }}
                  </v-icon>
                  {{ item.nombre }}
                </v-list-item-title>
              </v-list-item>

            </div>
          </v-list>
        </v-menu>

      </v-tabs>

      <v-spacer></v-spacer>

      <v-badge
          :content="getCantidadDocumentosCarro"
          color="red"
          overlap

      >
        <v-btn class="black--text" small color="white" outlined @click="irAlCarro()">
          <v-icon class="mr-1" small>mdi-cart</v-icon>
          {{ getPrecioTotalCarro | clp }}
        </v-btn>
      </v-badge>

      <v-btn
          v-if="nombreUsuario == null"
          @click="handleIniciarSesion()"
          v-show="$vuetify.breakpoint.smAndUp"
          class="ml-3 black--text"
          small
          color="white"
          outlined
      >
        <v-icon class="mr-1" small>mdi-login</v-icon>
        Ingresar
      </v-btn>

      <v-menu
          v-else
          offset-y
          open-on-hover
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-show="$vuetify.breakpoint.smAndUp"
              class="ml-3 black--text"
              small
              color="white"
              outlined
              v-bind="attrs"
              v-on="on"
          >
            <v-icon class="mr-1" small>mdi-account</v-icon>
            {{ nombreUsuario }}
          </v-btn>
        </template>

        <v-list dense flat>
          <v-list-item to="/cliente">
            <v-list-item-title>Mi cuenta</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleCerrarSesion()">
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import usuario from "@/model/usuario/usuario.entity";
import carro from "@/model/carro/carro.entity"
import {EventBus} from "@/main";

export default {
  name: "WebPageCompoundHeader",
  data() {
    return {
      menuTramitesEnLinea: [
        {
          nombre: "Registro de Propiedad",
          icono: "mdi-home",
          hijos: [
            {
              nombre: "Estudio de título 10 años",
              link: "/tramites/propiedad/estudio-titulo",
              nuevo: true
            },
            {
              nombre: "Copia con Vigencia o Dominio Vigente",
              link: "/tramites/propiedad/copias-y-certificados-propiedad/copia-con-vigencia-o-dominio-vigente",
              nuevo: false,
            },
            {
              nombre: "Cert. de Hip. y Gravámenes",
              link: "/tramites/propiedad/copias-y-certificados-propiedad/cert-hipotecas-gravamenes",
              nuevo: false,
            },
            {
              nombre: "Otros trámites",
              link: "/tramites/propiedad",
              nuevo: false,
            },
            {
              nombre: "Inscripción de Propiedad",
              link: "/inscripciones/inscripcion-propiedad",
              nuevo: true,
            }
            ,
            {
              nombre: "Alzamiento de Prohibición Serviu",
              link: "/inscripciones/alzamiento-prohibicion-serviu",
              nuevo: true,
            }
          ]
        },
        {
          nombre: "Registro de Comercio",
          icono: "mdi-store",
          hijos: [
            {
              nombre: "Copia con Vigencia",
              link: "/tramites/comercio/copias-y-certificados-comercio/copia-con-vigencia",
              nuevo: false,
            },
            {
              nombre: "Cert. de Vigencia de Sociedad",
              link: "/tramites/comercio/copias-y-certificados-comercio/cert-vigencia-sociedad",
              nuevo: false,
            },

            // {
            //   nombre: "Inscripción de Comercio (Diario Oficial)",
            //   link: "/inscripciones/comercio-diario-oficial"
            // },
            {
              nombre: "Constitución de Sociedad",
              link: "/inscripciones/constitucion-de-sociedad",
              nuevo: true,
            },
            {
              nombre: "Modificación de Sociedad",
              link: "/inscripciones/modificacion-de-sociedad",
              nuevo: true,
            },
            {
              nombre: "Otros trámites",
              link: "/tramites/comercio",
              nuevo: false,
            }
          ]
        },

        {
          nombre: "Registro de Aguas",
          icono: "mdi-water-pump",
          link: "/tramites/aguas",
          nuevo: false,
        },
        {
          nombre: "Registro de Minas",
          icono: "mdi-pickaxe",
          link: "/tramites/minas",
          nuevo: false,
        },
        {
          nombre: "Escrituras públicas",
          icono: "mdi-file",
          link: "/tramites/escrituras-publicas",
          nuevo: true,
        }
      ],
      menuConsultaEnLinea: [
        {
          nombre: "Estado de carátula",
          icono: "mdi-briefcase",
          link: "/consultas/caratulas",
          nuevo: false,
        },
        // {
        //   nombre: "Entrega en línea",
        //   icono: "mdi-briefcase-download",
        //   link: "/consultas/entrega-en-linea"
        // },
        {
          nombre: "Verificación de documentos",
          icono: "mdi-file-check",
          link: "/consultas/verificar-documento",
          nuevo: false,
        },
        {
          nombre: "Índices Registro Propiedad y Aguas",
          icono: "mdi-home",
          link: "/consultas/indices-propiedad-aguas",
          nuevo: false,
        },
        {
          nombre: "Índices Registro de Comercio",
          icono: "mdi-store",
          link: "/consultas/indices-comercio",
          nuevo: true,
        },
        {
          nombre: "Índices de Escrituras Públicas",
          icono: "mdi-file",
          link: "/consultas/escrituras-publicas",
          nuevo: true,
        },
        {
          nombre: "Consulta de Planos",
          icono: "mdi-map",
          link: "/consultas/planos",
          nuevo: false,
        },
      ],
      menuInformacion: [
        {
          nombre: "Acerca del conservador",
          icono: "mdi-information",
          link: "/informacion/acerca-de",
          nuevo: false,
        },
        {
          nombre: "Términos y condiciones",
          icono: "mdi-file-document-edit",
          link: "/informacion/tyc",
          nuevo: false,
        },
        {
          nombre: "Preguntas frecuentes",
          icono: "mdi-help-circle",
          link: "/informacion/faq",
          nuevo: false,
        },
        {
          nombre: "Trabaja con nosotros",
          icono: "mdi-account-hard-hat",
          link: "/informacion/trabaja-con-nosotros",
          nuevo: false,
        },
        {
          nombre: "Contacto",
          icono: "mdi-card-account-mail",
          link: "/informacion/contacto",
          nuevo: false,
        },
      ]
    }
  },
  computed: {
    nombreUsuario: function () {
      return usuario.getNombreUsuario();
    },
    getPrecioTotalCarro() {
      return carro.obtenerResumen().precio_total
    },
    getCantidadDocumentosCarro() {
      return carro.obtenerResumen().cantidad_documentos
    }
  },

  created() {
    carro.obtenerCarro();
  },
  methods: {
    irAlCarro() {
      this.$router.push("/carro");
    },
    obtenerCarro() {
      carro.obtenerCarro();
    },
    showDrawer() {
      this.$emit("show-drawer");
    },
    handleIniciarSesion() {
      EventBus.$emit("modal-login", true);
    },
    handleCerrarSesion() {
      usuario.cerrarSesionUsuario().then(() => {
        EventBus.$emit("dialog-sesion-finalizada", true);
        EventBus.$emit("login", false);
      }).catch((error) => {
        console.error(error);
      })
    }
  },
};
</script>

<style scoped>
.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: 31px;
}

.v-tab {
  font-size: 0.8rem;
  padding: 0 10px;
}

.v-tab .v-icon {
  margin-left: 0;
}
.iconColor{
  color: dimgray !important;
}
</style>
