<template>
  <v-row justify="center">
    <v-dialog
        v-model="verDialogSerie"
        persistent
        max-width="500"
    >
      <v-card v-if="serie== null">
        <v-card-title class="headline">
          <span class="titulo-serie">No se ha encontrado los datos de foja, número y año</span>
        </v-card-title>
        <v-card-text class="pb-2">La inscripción citada no ha sido encontrada en estos momentos, es probable que
          corresponda a un
          título muy reciente o que los datos estén mal tipeados. Si usted está seguro(a) de
          que los datos citados son correctos podrá continuar con la solicitud.

          <div class="text-right pt-5">
            <v-btn
                color="red lighten-1"
                text
                @click="closeModal"
            >
              Corregir datos
            </v-btn>

            <v-btn
                color="green darken-1"
                text
                @click="agregar"

            >
              Entiendo, agregar de todas formas
            </v-btn>
          </div>
        </v-card-text>

      </v-card>

      <v-card v-if="serie!= null">
        <v-card-title>Verifique su foja</v-card-title>
        <v-card-text v-if="serie.foja != serie.fojaBuscada">No hemos encontrado su foja, ¿Quiso decir {{ serie.foja }}
          - {{ serie.numero }} -
          {{ serie.anho }}?
        </v-card-text>
        <v-card-text v-if="serie.foja == serie.fojaBuscada">Se ha encontrado su foja, numero y año. ¿La dirección es
          correcta?
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-text style="text-align: justify">
          <v-icon x-small left class="info--text">mdi-map-marker</v-icon>
          <span>{{ serie.direccion }}<span class="info--text pointer" v-if="getRutUsuario == null" @click="verMas()">Ver más </span>
          </span>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red lighten-1"
              text
              @click="closeModal"
          >
            No es mi dirección
          </v-btn>
          <v-btn
              color="green darken-1"
              text
              @click="agregar"
          >
            Si, es mi dirección
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-row>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
  name: "ModalSerie",
  props: {
    verDialogSerie: {
      default: false
    },
    serie: {
      default: null
    },

  },
  data() {
    return {
      dialog: false,
    }
  },
  watch: {
    getRutUsuario(){
      this.$emit("recargarBusqueda", null);
    }
  },
  methods: {
    closeModal() {
      this.$emit("cerrarModalSerie", false);
    },
    agregar() {
      this.$emit("agregarDocumentos", this.serie == null ? null : this.serie.foja)
    },
    verMas(){
      EventBus.$emit("modal-login", true);
    }
  },
  computed: {
    getRutUsuario() {
      return usuarioEntity.getRutUsuario()
    }
  }
}
</script>

<style scoped>
.titulo-serie {
  word-break: normal;
}
</style>
